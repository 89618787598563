import { Fragment } from "react";

import { toNorwegianDateFormat } from "src/common/formatting";

import { Typography } from "@components/Typography";
import { utils } from "@features/agreements/avtale.module";
import { formatNumber } from "@fremtind/jkl-formatters-util";
import { type AgreementDetails, AgreementField } from "@model/gen";

import "./AgreementDetailSection.scss";

type DetaljerProps = {
    agreementDetails: AgreementDetails;
};

type Val = {
    value: string;
    type: AgreementField["type"];
};

export function AgreementDetailSection({ agreementDetails }: Readonly<DetaljerProps>) {
    return (
        <div className="agreement-details__section">
            <Typography variant="heading-2" component="h2" className="mb-24">
                Detaljer
            </Typography>
            <dl className="agreement-details__section__details">
                {transformDetails(agreementDetails) //add filter that filters out double values:
                    .filter((v, i, a) => a.findIndex((t) => t.description === v.description) === i)
                    .map(({ description, values }, index) => (
                        <Fragment key={index}>
                            <dt className="agreement-details__section__details__term">{description}</dt>
                            <dd key={index} className="agreement-details__section__details__detail">
                                {values.map((val: Val, index: number) => (
                                    <span key={index} className="agreement-details__section__details__detail__value">
                                        {utils.formatValue(val.value, val.type)}
                                    </span>
                                ))}
                            </dd>
                        </Fragment>
                    ))}
            </dl>
        </div>
    );
}

function returnMilage(value: string | undefined) {
    if (value && value !== "Ubegrenset") {
        return `${formatNumber(value.replace(/\D/g, ""))} km`;
    } else {
        return value;
    }
}

function transformDetails(detaljer: AgreementDetails): Array<any> {
    const detailObjects = detaljer.objects;
    const fields = detailObjects.map((o) => o.fields);

    const getFieldValue = (fieldLabel: string) => {
        return fields.flat().find((f) => f.description === fieldLabel)?.value;
    };

    return [
        {
            description: "Kjørelengde",
            values: [{ value: getFieldValue("Kjørelengde"), type: "STRING" }],
            type: "STRING"
        },
        {
            description: `Kilometerstand \n(${getFieldValue("Siste avlesning") && toNorwegianDateFormat(getFieldValue("Siste avlesning"))})`,
            values: [
                {
                    value: `${
                        getFieldValue("Kilometerstand")
                            ? returnMilage(getFieldValue("Kilometerstand"))
                            : getFieldValue("Km.stand")
                              ? returnMilage(getFieldValue("Km.stand"))
                              : ""
                    }`,
                    type: "STRING"
                },
                {
                    value: getFieldValue("Begrensning"),
                    type: "STRING"
                }
            ]
        },
        {
            description: "Bonus",
            values: [{ value: getFieldValue("Bonus"), type: "STRING" }]
        }
    ];
}
