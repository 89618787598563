import { isRadgiver } from "src/common/rolle";

import { GreenCheckIcon, RedCrossIcon } from "@fremtind/jkl-icons-react";
import { useMutation } from "@tanstack/react-query";
import { http } from "@features/core";
import { PrimaryButton } from "../../components/Button";
import { Side, SideHeader, SideInnhold } from "../../components/Side";
import { Toast } from "../../components/Toast";
import { Typography } from "../../components/Typography";
import { useBruker } from "../../features/bruker/queries";
import { RRHExpandableLovkrav } from "../../features/rrh/components/RRHExpandableLovkrav";
import { ReelleRettighetshavereTabell } from "../../features/rrh/components/ReelleRettighetshavereContent";
import { useReelleRettighetshavere } from "../../features/rrh/queries";
import { updateTask, useTasks } from "../../features/tasks/tasks.queries";
import { Trackingkey } from "../../tracking";
import "./ReelleRettighetshavere.scss";

export function ReelleRettighetshavere() {
    const { bruker, valgtOrganisasjon } = useBruker();
    const taskList = useTasks();
    const rrhTask = taskList.data?.find((task) => task.subjects?.includes("Reelle rettighetshavere"));

    const query = useReelleRettighetshavere();
    const bekreftRRH = (organisasjonsnummer: string) =>
        http.post(organisasjonsnummer + "/reellerettighetshavere/bekreft");

    const confirmMutation = useMutation({ mutationFn: bekreftRRH });

    return (
        <Side>
            <SideHeader
                title="Reelle rettighetshavere"
                lead={
                    <>
                        <Typography className="jkl-spacing-m--bottom" data-testid="rrh-ingress" >
                         Ifølge hvitvaskingslovens § 14, er vi pålagt å hente inn informasjon om såkalte reelle rettighetshavere i bedrifter, lag, foreninger, organisasjoner og stiftelser som har et avtaleforhold hos oss. Vi ber deg om å bekrefte at informasjonen under stemmer. Du kan når som helst endre disse opplysningene i portalen/kundeløsningen.
                        </Typography>

                        <RRHExpandableLovkrav />
                    </>
                }
            />
            <SideInnhold
                loadingText="Henter liste over reelle rettighetshavere"
                query={query}
                className="bedrift-oversikt"
            >
                {query.data && (
                    <>
                        <ReelleRettighetshavereTabell rows={query.data.reelleRettighetshavere} />
                        <div className="jkl-spacing-2xl--top">
                            <Typography variant="heading-3">Bekreft reelle rettighetshavere</Typography>
                            {isRadgiver(bruker) ? (
                                <Typography className="jkl-spacing-2xl--top">
                                    Rådgivere kan ikke bekrefte reelle rettighetshavere på vegne av kunder.
                                </Typography>
                            ) : (
                                <>
                                    <Typography className="jkl-spacing-l--bottom">
                                        Jeg bekrefter at listen over reelle rettighetshavere er korrekt og fullstendig.
                                    </Typography>
                                    <PrimaryButton
                                        onClick={() => {
                                            confirmMutation.mutate(valgtOrganisasjon!.organisasjonsnummer, {
                                                onSuccess: () => {
                                                    if (rrhTask && rrhTask.taskId) {
                                                        updateTask(rrhTask.taskId, "utført");
                                                    }
                                                }
                                            });
                                        }}
                                        dataTestautoid="bedrift-oversikt-knapp-bekreft-reel-rettighetshaver-liste"
                                        track={{
                                            hendelse: Trackingkey.Knappetrykk,
                                            knappeId: "bedrift-oversikt-knapp-bekreft-reel-rettighetshaver-liste"
                                        }}
                                        loader={{
                                            showLoader: confirmMutation.isPending,
                                            textDescription: "bekrefter reelle rettighetshavere"
                                        }}
                                        role="button"
                                    >
                                        Bekreft
                                    </PrimaryButton>
                                </>
                            )}
                            <div className="confirmed-status jkl-spacing-32--top">
                                {query.data.bekreftet || confirmMutation.isSuccess ? (
                                    <>
                                        <GreenCheckIcon />
                                        <Typography>Reelle rettighetshavere har blitt bekreftet.</Typography>
                                    </>
                                ) : (
                                    <>
                                        <RedCrossIcon />
                                        <Typography>Reelle rettighetshavere har ikke blitt bekreftet.</Typography>
                                    </>
                                )}
                            </div>
                        </div>
                        <Toast
                            severity="success"
                            open={confirmMutation.isSuccess}
                            onClose={confirmMutation.reset}
                            title="Du har bekreftet listen over reelle rettighetshavere"
                        >
                            Du kan fortsatt legge til rettighetshavere i listen.
                        </Toast>
                        <Toast
                            severity="error"
                            open={confirmMutation.isError}
                            onClose={confirmMutation.reset}
                            title="Vi har gjort en feil"
                        >
                            Beklager, vi har en feil i våre systemer. Prøv igjen senere eller kontakt oss på telefon.
                        </Toast>
                    </>
                )}
            </SideInnhold>
        </Side>
    );
}
