import { AgreementDetails } from "@model/gen";
import { Link } from "react-router-dom";
import { toNorwegianDateFormat } from "src/common/formatting";

interface Props  {
    avtale: AgreementDetails
}

export const PrefornyelseBil = ({avtale}: Props) => {
    return (
        <>
            Vi ber deg sjekke om kjørelengden er riktig. Hvis du ønsker å gjøre endringer i dekning og/eller egenandel,{" "} 
            <Link to="/kontakt-oss" className="jkl-link">
            ta kontakt med rådgiver
            </Link>
            . Nytt avtaledokument og pris blir tilgjengelig {avtale.endDate && toNorwegianDateFormat(avtale.endDate)}.
        </>
    );
};
