import { toNorwegianDateFormat } from "src/common/formatting";

import { Typography } from "@components/Typography";
import { type AgreementDetails, AgreementField } from "@model/gen";

import { AgreementDetailInstance } from "./AgreementDetailInstance/AgreementDetailInstance";
import styles from "./AgreementDetailSection.module.scss";

type DetaljerProps = {
    agreementDetails: AgreementDetails;
};



export function AgreementDetailSection({ agreementDetails }: Readonly<DetaljerProps>) {
    return (
        <div className={styles.topDetails}>
            <Typography variant="heading-2" component="h2">
                Detaljer
            </Typography>
            <dl className={styles.root}>
                {transformDetails(agreementDetails)
                    .concat(agreementDetails.additionalInfo ?? [])
                    .map(({ description, value, type }) => (
                       agreementDetails.productCode === "P90" && description === "Årlig pris" ? null : (
                        <AgreementDetailInstance
                            key={description}
                            description={description}
                            value={value}
                            type={type}
                        />
                       )
                    ))}
            </dl>
        </div>
    );
}



function transformDetails(detaljer: AgreementDetails): Array<AgreementField> {
    return [
        {
            description: "Avtalenummer",
            value: detaljer.agreementId.toString(),
            type: "STRING"
        },
        {
            description: "Årlig pris",
            value: detaljer.annualPremium.toString(),
            type: "CURRENCY"
        },
        {
            description: "Avtaleperiode",
            value: `${toNorwegianDateFormat(detaljer.startDate)} - ${toNorwegianDateFormat(detaljer.endDate)}`,
            type: "STRING"
        }
    ];
}
