import { useFormContext } from "react-hook-form";

import { Typography } from "@components/Typography";
import { ReservationFormData } from "@features/reservation/ReservationForm/ReservationForm";
import { Accordion, AccordionItem } from "@fremtind/jkl-accordion-react";
import { UnorderedList } from "@fremtind/jkl-list-react";
import { ToggleSwitch } from "@fremtind/jkl-toggle-switch-react";

import "./ReservationForm.scss";

interface Props {
    isDNB: boolean;
}

export function ReservationDataSharing({ isDNB }: Props) {
    const { register, watch, setValue } = useFormContext<ReservationFormData>();

    const dataSharing = watch("dataSharing");

    if (isDNB) return null;

    return (
        <section data-testid="deling-section">
            <hgroup>
                <Typography variant="heading-3">Deling av data</Typography>
                <Typography>
                    Når du deler data om kundeforholdet ditt blir informasjonen delt mellom selskapene i SpareBank
                    1-konsernet.
                </Typography>
            </hgroup>

            <ToggleSwitch
                aria-pressed={dataSharing}
                {...register("dataSharing")}
                onChange={(e, pressed) => {
                    setValue("dataSharing", pressed);
                }}
                className="samtykker__ekunde-section__toggle"
                data-testid="samtykke-toggle-deling-av-data"
                data-testautoid="samtykke-toggle-deling-av-data"
                type={"submit"}
            >
                Del data med SpareBank 1-konsernet
            </ToggleSwitch>

            <Accordion>
                <AccordionItem title="Hva betyr deling av data?" data-testid="expander-sharing-data">
                    Informasjonen brukes til å:
                    <UnorderedList>
                        <li>
                            vise informasjon fra både liv- og skadeforsikring til forsikringsrådgivere, slik at du får
                            best mulig rådgivning.
                        </li>
                        <li>forstå hvordan du bruker digitale løsninger, slik at vi kan gjøre løsningene bedre.</li>
                        <li>
                            samle informasjon på tvers av bank, pensjon og forsikring slik at vi kan gi deg relevante
                            tilbud.
                        </li>
                    </UnorderedList>
                    Det er SpareBank 1 Forsikring og Fremtind Forsikring som har ansvar for å behandle opplysningene. Du
                    har rett til innsyn. Opplysningene blir ikke delt utenfor SpareBank 1-konsernet.
                </AccordionItem>
            </Accordion>
        </section>
    );
}
