import { useParams } from "react-router-dom";

import { ErrorMessageBox } from "@fremtind/jkl-message-box-react";

import { Side, SideInnhold } from "../../../components/Side";
import { SideHeader } from "../../../components/Side";
import { TTPNyTilgang } from "../components/TTPNyTilgang";
import { useTilgangerTredjePart } from "../queries";

export const GiTilgangBedrift = () => {
    const query = useTilgangerTredjePart();
    const { orgnummer } = useParams();

    return (
        <Side compact>
            <SideHeader title="Gi tilgang" />
            <SideInnhold loadingText="Henter tilganger" query={query} ignoreError>
                {query.error ? (
                    <ErrorMessageBox title="Oi, litt tekniske problemer her">
                        Vi klarer ikke å hente virksomheter og virksomhetsansvarlige akkurat nå. Prøv en gang til eller kom
                        tilbake senere.
                    </ErrorMessageBox>
                ) : (
                    <>
                        {query.data && (
                            <TTPNyTilgang
                                flytnavn="tilgang på virksohmet"
                                subject={
                                    query
                                        .data!.tilganger.bedrifter.map(({ bedrift }) => bedrift)
                                        .find((bedrift) => bedrift.organisasjonsnummer === orgnummer)!
                                }
                                options={query.data.tilganger.kamer
                                    .filter(
                                        (kam) =>
                                            // finn bedriftsansvarlige som ikke allerede har tilgang på virksomheten
                                            !kam.bedrifter.some(
                                                ({ bedrift }) => bedrift.organisasjonsnummer === orgnummer
                                            )
                                    )
                                    .map(({ kam }) => kam)}
                                tilganger={query.data.tilganger}
                            />
                        )}
                    </>
                )}
            </SideInnhold>
        </Side>
    );
};
