import { AgreementDetails } from "@model/gen";

import { PrefornyelseAntallKanEndres } from "./PreRenewalTexts/PrefornyelseAntallKanEndres";
import { PrefornyelseAntallKanIkkeEndres } from "./PreRenewalTexts/PrefornyelseAntallKanIkkeEndres";
import { PrefornyelseNavnKanEndres } from "./PreRenewalTexts/PrefornyelseNavnKanEndres";
import { PrefornyelseNavnKanIkkeEndres } from "./PreRenewalTexts/PrefornyelseNavnKanIkkeEndres";
import { FornyelseAntallKanEndres } from "./RenewalTexts/FornyelseAntallKanEndres";
import { FornyelseAntallKanIkkeEndres } from "./RenewalTexts/FornyelseAntallKanIkkeEndres";
import { FornyelseNavnKanEndres } from "./RenewalTexts/FornyelseNavnKanEndres";
import { FornyelseNavnKanikkeEndres } from "./RenewalTexts/FornyelseNavnKanIkkeEndres";
import { FornyelseBil } from "./RenewalTexts/FornyelseBil";
import { PrefornyelseBil } from "./PreRenewalTexts/PrefornyelseBil";

interface Props {
    agreementDetails: AgreementDetails;
}

export const AgreementRenewalText = ({ agreementDetails }: Props) => {
    if (!agreementDetails.nextVersion) return null;

    const isVehicleAgreement = agreementDetails.category === "KJORETOY";
    const isV05Agreement =  agreementDetails.productCode === "V05";
    const isInRenewalPhase = agreementDetails.renewalPhase === "RENEWAL";
    const isInPreRenewalPhase = agreementDetails.renewalPhase === "PRE_RENEWAL";
    const isNumberBased = agreementDetails.groupInsuranceType === "NUMBER_BASED";
    const isChangeable = agreementDetails.changeable;


    if (isInPreRenewalPhase) {
        if (isV05Agreement) {
            return <PrefornyelseBil avtale={agreementDetails} />;
        } else if (isNumberBased) {
            if (isChangeable && !isVehicleAgreement) {
                return <PrefornyelseAntallKanEndres avtale={agreementDetails} />;
            } else {
                return <PrefornyelseAntallKanIkkeEndres />;
            }
        } else {
            if (isChangeable && !isVehicleAgreement) {
                return <PrefornyelseNavnKanEndres />;
            } else {
                return <PrefornyelseNavnKanIkkeEndres />;
            }
        }
    } else if (isInRenewalPhase) {
        if (isV05Agreement) {
            return <FornyelseBil avtale={agreementDetails} />;
        } else if (isNumberBased) {
            if (isChangeable && !isVehicleAgreement) {
                return (
                    <FornyelseAntallKanEndres
                        currentAgreement={agreementDetails}
                        newAgreement={agreementDetails.nextVersion}
                    />
                );
            } else {
                return (
                    <FornyelseAntallKanIkkeEndres
                        currentAgreement={agreementDetails}
                        newAgreement={agreementDetails.nextVersion}
                    />
                );
            }
        } else {
            if (isChangeable && !isVehicleAgreement) {
                return (
                    <FornyelseNavnKanEndres
                        currentAgreement={agreementDetails}
                        newAgreement={agreementDetails.nextVersion}
                    />
                );
            } else {
                return (
                    <FornyelseNavnKanikkeEndres
                        currentAgreement={agreementDetails}
                        newAgreement={agreementDetails.nextVersion}
                    />
                );
            }
        }
    }

    return null;
};
