import { useContext } from "react";

import { isRadgiver } from "src/common/rolle";
import ThemeContext from "src/context/ThemeContext";
import { Distributor } from "src/model/theme.typer";

import { Side, SideHeader, SideInnhold } from "@components/Side";
import { useBruker } from "@features/bruker/queries";
import { ErrorMessageBox } from "@fremtind/jkl-message-box-react";

import { ReservationForm } from "../ReservationForm";
import { useReservation } from "../reservation.queries";
import "./Reservation.scss";
import { ReservationLoader } from "./ReservationLoader";

export default function Reservation() {
    const { bruker } = useBruker();
    const { distributor } = useContext(ThemeContext);
    const query = useReservation();

    if (!bruker) return null;

    const reservation = !query.data?.samtykke.ekunde?.kundeSamtykkeGitt || false;
    const dataSharing = query.data?.samtykke.delingAvData?.kundeSamtykkeGitt || false;
    const email = query.data?.kontaktInfo.epost?.epost || "";

    return (
        <Side compact>
            <SideHeader title="Samtykker" />
            <SideInnhold loadingText="Laster inn samtykke" query={query} disableLoader={true}>
                {query.isLoading && <ReservationLoader />}
                {query.isError && <ErrorMessageBox>Klarte ikke hente informasjon om samtykke.</ErrorMessageBox>}
                {query.data && (
                    <ReservationForm
                        isRadgiver={isRadgiver(bruker)}
                        isDNB={distributor === Distributor.DNB}
                        formInfo={{ reservation, dataSharing, email }}
                    />
                )}
            </SideInnhold>
        </Side>
    );
}
