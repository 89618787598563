import React from "react";

import { useFormContext } from "react-hook-form";

import { Typography } from "@components/Typography";
import { ReservationFormData } from "@features/reservation/ReservationForm/ReservationForm";
import { Accordion, AccordionItem } from "@fremtind/jkl-accordion-react";
import { Button } from "@fremtind/jkl-button-react";
import { TextInput } from "@fremtind/jkl-text-input-react";
import { ToggleSwitch } from "@fremtind/jkl-toggle-switch-react";
import { isValidEpost } from "@fremtind/jkl-validators-util";

import "./ReservationForm.scss";

export function ReservationDigitalCommunication() {
    const {
        register,
        watch,
        setValue,
        formState: { errors, isSubmitting }
    } = useFormContext<ReservationFormData>();

    const reservation = watch("reservation");
    const email = watch("email");

    return (
        <section data-testid="samtykker__ekunde-section">
            <hgroup>
                <Typography variant="heading-3">Digital kommunikasjon</Typography>
                <Typography>
                    For å motta forsikringsdokumentene i posten, må du reservere deg mot digital kommunikasjon.
                </Typography>
            </hgroup>

            <div className="samtykker__ekunde-section__toggle">
                <ToggleSwitch
                    aria-pressed={reservation}
                    {...register("reservation")}
                    onChange={(e, pressed) => {
                        setValue("reservation", pressed);
                    }}
                    data-testid="samtykke-toggle-ekunde"
                    data-testautoid="samtykke-toggle-ekunde"
                >
                    Jeg vil reservere meg og få dokumentene i posten
                </ToggleSwitch>
                {reservation && (
                    <Typography variant={"small"} subdued={true}>
                        Du mottar forsikringdokumentene i posten
                    </Typography>
                )}

                {!reservation && (
                    <div className="samtykker__ekunde-section__toggle__email">
                        <TextInput
                            autoComplete="email"
                            label="Forsikringdokumentene sendes til"
                            {...register("email", {
                                required: "Du må fylle inn e-post",
                                validate: (value) => (isValidEpost(value) ? true : "Ugyldig e-post")
                            })}
                            errorLabel={errors.email?.message}
                            className="samtykke__epost"
                            data-testautoid="samtykke-input-epost"
                            data-testid="samtykke-input-epost"
                            width="100%"
                            onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                    e.preventDefault();
                                }
                            }}
                            helpLabel={"Mottaker av forsikringsdokumentene."}
                        />

                        {isValidEpost(email) && (
                            <Button
                                data-testautoid="samtykke-button-epost"
                                variant="primary"
                                onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                                    if (!isValidEpost(email)) {
                                        e.preventDefault();
                                        return;
                                    }

                                    setValue("email", email);
                                }}
                                loader={{ showLoader: isSubmitting, textDescription: "Oppdaterer samtykke" }}
                                type={"submit"}
                            >
                                Lagre
                            </Button>
                        )}
                    </div>
                )}
            </div>

            <Accordion>
                <AccordionItem
                    title="Hva betyr det å motta forsikringsdokumentene digitalt?"
                    data-testid="expander-digital-communication"
                >
                    Med digital kommunikasjon mener vi e-post. Du blir varslet når et nytt dokument ligger klart i
                    kundeløsningen. Noen dokumenter kan likevel bli sendt i posten, da blir det ikke sendt et varsel.
                    Elektronisk kommunikasjon er likestilt med brevpost.
                </AccordionItem>
            </Accordion>
        </section>
    );
}
