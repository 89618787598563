import { BMRoute } from "src/sider";

import { ProtectedRoute } from "@components/ProtectedRoute";

import Reservation from "./views/Reservation";

export const reservationRoutes: BMRoute[] = [
    {
        path: "samtykker",
        children: [
            {
                element: (
                    <ProtectedRoute>
                        <Reservation />
                    </ProtectedRoute>
                ),
                breadcrumbLabel: "Samtykker",
                index: true
            }
        ]
    }
];
