import { useContext } from "react";

import { ABTest, Variant } from "src/common/experiments/ABTest";
import { useMarkdown } from "src/common/hooks/useMarkdown";
import ThemeContext from "src/context/ThemeContext";
import { Trackingkey, track } from "src/tracking";

import { TertiaryLinkButton } from "@components/Button";
import { AvtaleProduktID } from "@features/agreements/avtale.model";
import { useAgreementInfo } from "@features/agreements/hooks/useAgreementInfo";
import { useProductPath } from "@features/kjop/kjop.queries";
import { AccordionItem } from "@fremtind/jkl-accordion-react";

export const ProductAccordion: React.FC<{
    product: {
        productCode: AvtaleProduktID;
        productName: string;
    };
}> = ({ product }) => {
    const productObject = useAgreementInfo(product.productCode);
    const { distributor } = useContext(ThemeContext);
    const flowPath = useProductPath(product.productCode);

    const DescriptionAsMarkdown = useMarkdown(productObject?.description);
    if (!productObject || !productObject.productInfo) return null;

    const { productInfo } = productObject;

    const distributorHasProduct = !!productInfo.href[distributor];

    if (!distributorHasProduct) return null;

    const formLink = flowPath ? flowPath : "/bedrift/kjop/kontakt/?product=" + product.productName;

    return (
        <AccordionItem
            onClick={(e, isOpen) => {
                if (!isOpen) return;

                track({
                    hendelse: Trackingkey.KjopMostPopular,
                    produkt: product.productCode,
                    element: "ACCORDION"
                });
            }}
            title={product.productName}
        >
            <div className="jkl-spacing-l--bottom">{DescriptionAsMarkdown}</div>
            <div className="product-accordion__buttons">
                {/* <a> fremfor <Link> siden `to` blir forvirret av en annen app på samme origin,
                  og resolver til en 404
                */}
                <a
                    className="jkl-button jkl-button--primary"
                    onClick={() => {
                        track({
                            hendelse: Trackingkey.KjopMostPopular,
                            produkt: product.productCode,
                            element: "BESTILL_KNAPP"
                        });
                    }}
                    href={formLink || "/"}
                >
                    <ABTest testName="Navn på knapp på ny kjøpsside">
                        <Variant variantName="Be om tilbud" variant="A">
                            Be om tilbud
                        </Variant>

                        <Variant variantName="Bestill forsikring" variant="B">
                            Bestill forsikring
                        </Variant>
                    </ABTest>
                </a>
                {productInfo.href[distributor] && (
                    <TertiaryLinkButton
                        onClick={() => {
                            track({
                                hendelse: Trackingkey.KjopMostPopular,
                                produkt: product.productCode,
                                element: "LES_MER_KNAPP"
                            });
                        }}
                        to={productInfo.href[distributor]}
                        target="_blank"
                    >
                        Les mer om forsikringen
                    </TertiaryLinkButton>
                )}
            </div>
        </AccordionItem>
    );
};
