import { useEffect, useState } from "react";

import { Link, useLocation } from "react-router-dom";
import { toNorwegianDateFormat } from "src/common/formatting";
import { Trackingkey, track } from "src/tracking";

import { useBruker } from "@features/bruker/queries";
import { formatValuta } from "@fremtind/jkl-formatters-util";
import { ErrorMessageBox, WarningMessageBox } from "@fremtind/jkl-message-box-react";
import { usePreviousValue } from "@fremtind/jkl-react-hooks";
import { InvoiceReminderDto } from "@model/gen";

import * as utils from "./utils";

interface Props {
    invoiceReminders: InvoiceReminderDto[];
}

export const InvoiceReminder = ({ invoiceReminders }: Props) => {
    const { pathname } = useLocation();
    const { valgtOrganisasjon, bruker } = useBruker();
    const [dismissed, setDismissed] = useState(sessionStorage.getItem("bm-invoice-alert-dismissed"));
    const [logged, setLogged] = useState(sessionStorage.getItem("bm-invoice-alert-mixpanel"));
    const previousChosenOrganisation = usePreviousValue(valgtOrganisasjon?.organisasjonsnummer);

    useEffect(() => {
        if (previousChosenOrganisation ? previousChosenOrganisation !== valgtOrganisasjon?.organisasjonsnummer : "") {
            sessionStorage.clear();
            setDismissed(null);
            setLogged(null);
        }
    }, [previousChosenOrganisation, valgtOrganisasjon?.organisasjonsnummer]);

    const mostCriticalInvoice = utils.getMostCriticalInvoice(invoiceReminders);
    const invoiceState = mostCriticalInvoice ? utils.calculateInvoiceReminderState(mostCriticalInvoice) : undefined;

    useEffect(() => {
        if (!logged && invoiceState) {
            sessionStorage.setItem("bm-invoice-alert-mixpanel", "true");
            setLogged("true");
            track({
                hendelse: Trackingkey.VarselFaktura,
                handling: "open",
                kategori: "faktura",
                type: invoiceState,
                variant: invoiceState === "forfalt_faktura" ? "warning" : "error"
            });
        }
    }, [logged, invoiceState]);

    const handleDismiss = () => {
        sessionStorage.setItem("bm-invoice-alert-dismissed", "true");
        setDismissed("true");
        track({
            hendelse: Trackingkey.VarselFaktura,
            handling: "close",
            kategori: "faktura",
            type: invoiceState!,
            variant: invoiceState === "forfalt_faktura" ? "warning" : "error"
        });
    };

    if (
        pathname.startsWith("/bytt-organisasjon") ||
        !bruker?.loggetInn ||
        dismissed ||
        !mostCriticalInvoice ||
        !invoiceState
    ) {
        return null;
    }

    if (invoiceState === "inkassovarsel") {

        return (
            <ErrorMessageBox
                className="bm-global-alert-message"
                dismissAction={{ handleDismiss }}
                data-testid="invoice-reminder-alert"
            >
                <b>Inkassovarsel</b>
                <p>
                    Dere har fått et etterkrav/inkassovarsel, og forsikringene deres er sagt opp.{" "}
                    Betal varselet for å unngå at saken blir sendt til inkasso. Ta kontakt med{" "}<Link to="/kontakt-oss" className="jkl-link">
                    rådgiver</Link>{" "} hvis du ønsker å starte forsikringene igjen.
                    {mostCriticalInvoice.hasTFA && (
                        <>
                            Vi gjør deg oppmerksom på at Trafikkforsikringsforeningen krever{" "}
                            <a
                                href="https://www.vegvesen.no/kjoretoy/eie-og-vedlikeholde/gebyr-for-uforsikret-kjoretoy/"
                                target="_blank"
                                rel="noreferrer"
                            >
                                dagsgebyr
                            </a>{" "}for uforsikrede kjøretøy.
                            .
                        </>
                    )}
                </p>
            </ErrorMessageBox>
        );
    }

    if (invoiceState === "forfalt_faktura") {
        return (
            <WarningMessageBox
                className="bm-global-alert-message"
                dismissAction={{ handleDismiss }}
                data-testid="invoice-reminder-alert"
                fullWidth
            >
                <b>Forfalt faktura</b>
                <p>
                    Du har en ubetalt faktura på beløp {formatValuta(mostCriticalInvoice.invoiceAmount)} som forfalt{" "}
                    {toNorwegianDateFormat(mostCriticalInvoice.invoiceDueDate)}. Hvis du nylig har betalt, kan du se
                    bort fra dette varselet.
                </p>
            </WarningMessageBox>
        );
    }

    if (invoiceState === "purring") {
        return (
            <ErrorMessageBox
                className="bm-global-alert-message"
                dismissAction={{ handleDismiss }}
                data-testid="invoice-reminder-alert"
                fullWidth
            >
                <b>Purring på faktura</b>
                <p>
                    Du har en ubetalt faktura på beløp {formatValuta(mostCriticalInvoice.invoiceAmount)} som er sendt
                    til purring med forfall {toNorwegianDateFormat(mostCriticalInvoice.invoiceDueDate)}.{" "}
                    Hvis du nylig har betalt, kan du se bort fra dette varselet.
                </p>
            </ErrorMessageBox>
        );
    }

    if (invoiceState === "purring_passert_forfallsdato") {
        return (
            <ErrorMessageBox
                className="bm-global-alert-message full-width"
                dismissAction={{ handleDismiss }}
                data-testid="invoice-reminder-alert"
                fullWidth
            >
                <b>Purring forfalt</b>
                <p>
                Vi ikke mottatt betaling for faktura på {formatValuta(mostCriticalInvoice.invoiceAmount)} kroner. 
                Betal med en gang for å unngå at forsikringene blir sagt opp.
                    {mostCriticalInvoice.hasTFA && (
                        <>
                            Vær oppmerksom på at Trafikkforsikringsforeningen krever{" "}
                            <a
                                href="https://www.vegvesen.no/kjoretoy/eie-og-vedlikeholde/gebyr-for-uforsikret-kjoretoy/"
                                target="_blank"
                                rel="noreferrer"
                                className="jkl-link"
                            >
                                dagsgebyr
                            </a>{" "}for uforsikrete kjøretøy. Hvis du nylig har betalt, kan du se bort fra dette varselet.
                        </>
                    )}
                </p>
            </ErrorMessageBox>
        );
    }

    return null;
};
