import {SkeletonAnimation, SkeletonElement} from "@fremtind/jkl-loader-react";

export function ReservationLoader() {
    return (
        <SkeletonAnimation>
            <SkeletonElement width={700} height={300} />
            <br />
            <br />
            <SkeletonElement width={700} height={300} />
        </SkeletonAnimation>
    );
}
