
import { AgreementOverview, TaskResponseDto } from "@model/gen";
import { useInvoiceReminders } from "@features/invoice/invoice.queries";
import { getMostCriticalInvoice, calculateInvoiceReminderState } from "@features/invoice/components/InvoiceReminder/utils";
import { AvtaleProduktID } from "@features/agreements/avtale.model";

export interface LocalTaskProps {
    fromDate?: Date;
    toDate?: Date;
    defaultVisible?: boolean;
    shouldShow?: boolean;

}

export const localSystemTask : TaskResponseDto & LocalTaskProps = 
        {
            title: "Systemoppgave",
            description: "Dette er en placeholder for en systemoppgave",
            category: "system",
            subjects: ["Systemoppgave"],
            status: "sendt",
            priority: "INFO",
            shouldShow: false
        };

export const GetLocalTasks = (agreements: AgreementOverview[] | undefined, showFornyelsesTask?: boolean) => {

    const { data } = useInvoiceReminders();
    const invoiceReminders = data?.entity?.invoiceReminders ?? [];
    const mostCriticalInvoice = getMostCriticalInvoice(invoiceReminders);

    const invoiceState = mostCriticalInvoice ? calculateInvoiceReminderState(mostCriticalInvoice) : undefined;
    const hasWorkerAgreements = agreements?.some((avtale) => avtale.category === "ANSATT" && avtale.productCode !== AvtaleProduktID.YRKESSKADE);
    const hasNiceAgreement =  agreements?.some((avtale) => avtale.coreSystem === "NICE");


    const localTasks = 
    [
    {
        title: "Du har forsikringer til fornyelse",
        description: "Se over avtalene dine og sjekk at alt stemmer.",
        url: "forsikringer",
        category: "Oppgave",
        subjects: ["Fornyelse"],
        status: "sendt",
        priority: "INFO",
        shouldShow: showFornyelsesTask ? showFornyelsesTask : false
    },
    {
    title: "Lønnsinnberetning",
    description: "Den skattepliktige delen av personalforsikringene er tilgjengelig",
    url: "dokumenter?dokumentFilter=Lønnsinnberetning",
    category: "Oppgave",
    subjects: ["Lønnsinnberetning"],
    status: "sendt",
    priority: "INFO",
    fromDate: new Date(2024, 9, 15),
    toDate: new Date(2025, 0, 31),
    shouldShow: hasWorkerAgreements
    },
     {
        title: "Regnskapsbilag (økonomisk bilag)",
        description: `Virksomhetens økonomiske transaksjoner for ${new Date().getFullYear()} er tilgjengelig`,
        url: "dokumenter?dokumentFilter=Regnskapsbilag",
        category: "Oppgave",
        subjects: ["Regnskapsbilag"],
        status: "sendt",
        priority: "INFO",
        fromDate: new Date(2025, 0, 13),
        toDate: new Date(2025, 2, 1),
        shouldShow: hasNiceAgreement
    },
     {
        title: "Forfalt faktura",
        description: "Hvis du nylig har betalt, kan du se bort fra dette varselet.",
        url: "faktura",
        category: "Oppgave",
        subjects: ["Forfalt faktura"],
        status: "sendt",
        priority: "IMPORTANT",
        shouldShow: invoiceState === "forfalt_faktura"
    },
    {
        title: "Purring på faktura",
        description: "Hvis du nylig har betalt kan du se bort fra varslet.",
        url: "faktura",
        category: "Oppgave",
        subjects: ["Forfalt faktura"],
        status: "sendt",
        priority: "VERY_IMPORTANT",
        shouldShow: invoiceState === "purring"
    },
     {
        title: "Purring forfalt",
        description: "Betal med en gang for å unngå at forsikringene blir sagt opp.",
        url: "faktura",
        category: "Oppgave",
        subjects: ["Purring på faktura"],
        status: "sendt",
        priority: "VERY_IMPORTANT",
        shouldShow: invoiceState === "purring_passert_forfallsdato"
    },
     {
        title: "Inkassovarsel",
        category: "Oppgave",
        status: "sendt",
        description: "Forsikringene er sagt opp. Du må kontakte rådgiver.",
        url: `/faktura?fakturanr=${mostCriticalInvoice?.invoiceNumber}`,
        priority: "VERY_IMPORTANT",
        shouldShow: invoiceState === "inkassovarsel"
    }
];
    return localTasks as TaskResponseDto[] & LocalTaskProps[];
};
