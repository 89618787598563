import { TaskResponseDto } from "@model/gen";


export const isDateWithinRange = (fromDate?: Date, toDate?: Date): boolean => {
    const today = new Date();
    if (fromDate && toDate) {
        return today >= fromDate && today <= toDate;
    }
    if (fromDate) {
        return today >= fromDate;
    }
    if (toDate) {
        return today <= toDate;
    }
    return true; // If no dates are provided, consider the task visible
};


export const filterTasks = (
    tasks: TaskResponseDto[],
    category?: string,
    status?: TaskResponseDto["status"][],
    agreementId?: string
) => {
    if (!tasks) {
        return [];
    }
    return tasks.filter(
        (task: TaskResponseDto) =>
            (category ? task.category?.toLowerCase() === category.toLowerCase() : true) &&
            (status
                ? status?.includes(task.status)
                : task.status?.includes("sendt") &&
                    agreementId
                  ? task.url?.includes(agreementId)
                  : true) &&
            task.url 
    );
};