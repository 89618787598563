import React from "react";

import { FormProvider, useForm } from "react-hook-form";
import { Trackingkey, track } from "src/tracking";

import { Toast } from "@components/Toast";

import { UpdateReservationQuery, useUpdateReservation } from "../reservation.queries";
import { ReservationDataSharing } from "./ReservationDataSharing";
import { ReservationDigitalCommunication } from "./ReservationDigitalCommunication";
import "./ReservationForm.scss";

export type ReservationFormData = {
    reservation: boolean;
    dataSharing: boolean;
    email: string;
};

const toSubmitData = (formData: UpdateReservationQuery): UpdateReservationQuery => ({
    email: formData.email,
    reservation: formData.reservation,
    dataSharing: formData.dataSharing
});

interface Props {
    isRadgiver: boolean;
    isDNB: boolean;
    formInfo: ReservationFormData;
}

export const ReservationForm: React.FC<Props> = ({ isRadgiver, isDNB, formInfo }: Props) => {
    const form = useForm<ReservationFormData>({
        defaultValues: {
            reservation: formInfo.reservation,
            dataSharing: formInfo.dataSharing,
            email: formInfo.email
        },
        shouldFocusError: false
    });

    const { handleSubmit, watch } = form;

    const reservation = watch("reservation");
    const dataSharing = watch("dataSharing");

    const mutation = useUpdateReservation();

    const onSubmit = (formData: UpdateReservationQuery) => {
        mutation.reset();

        mutation.mutate(toSubmitData(formData), {
            onSuccess: () => {
                track({
                    hendelse: Trackingkey.Samtykke,
                    ekunde: reservation ? "Nei" : "Ja",
                    delingAvData: dataSharing ? "Ja" : "Nei"
                });
            }
        });
    };

    return (
        <FormProvider {...form}>
            <form className="samtykker" onSubmit={handleSubmit(onSubmit)}>
                <ReservationDigitalCommunication />

                <ReservationDataSharing isDNB={isDNB} />

                <Toast
                    open={mutation.isPending}
                    onClose={mutation.reset}
                    severity="info"
                    data-testautoid="samtykke-toast-info"
                >
                    Oppdaterer samtykke...
                </Toast>

                <Toast
                    open={mutation.isSuccess}
                    onClose={mutation.reset}
                    severity="success"
                    data-testautoid="samtykke-toast-success"
                >
                    Samtykket ditt er oppdatert!
                </Toast>

                <Toast
                    open={mutation.isError}
                    onClose={mutation.reset}
                    severity="error"
                    data-testautoid="samtykke-toast-error"
                >
                    Kunne ikke oppdatere samtykke. Prøv en gang til eller kom tilbake senere.
                </Toast>

                <Toast
                    open={mutation.error?.status === 403 && isRadgiver}
                    onClose={mutation.reset}
                    severity="error"
                    data-testautoid="samtykke-toast-error-radgiver"
                >
                    Rådgiver kan ikke gjøre endringer på samtykke. Kunden må endre dette selv.
                </Toast>
            </form>
        </FormProvider>
    );
};
