import { queryClient } from "src/common/queryClient";
import type { TaskResponseDto } from "@model/gen/models/TaskResponseDto";

import { useBruker } from "@features/bruker/queries";
import { http } from "@features/core";
import { useQuery } from "@tanstack/react-query";


const taskeQueryKey = ["task"];

export async function getTaskList() {
   const data = await http.get<TaskResponseDto[]>("task");
    return data as TaskResponseDto[];
}

export const taskListQuery = () => {
    return queryClient.fetchQuery({
        queryKey: taskeQueryKey,
        queryFn: () => getTaskList()
    });
};

export const useTasks = () => {
    const { bruker } = useBruker();

    return useQuery({
        queryKey: taskeQueryKey,
        queryFn: () => getTaskList(),
        enabled: !!bruker?.gjeldendeOrganisasjonsnummer,
        staleTime: 1000 * 60 * 5
    });
};

export const updateTask = async (taskId: string, status: string) => {
    await http.post(`task/${taskId}/${status}`);
};

