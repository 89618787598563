import { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import { toFormattedOrganisasjonsnummer } from "src/common/formatting";
import { searchByRegex } from "src/common/utils";
import { Trackingkey, track } from "src/tracking";

import { SearchField } from "@components/SearchField";
import { Typography } from "@components/Typography";
import { useBruker } from "@features/bruker/queries";
import { useOrganizations } from "@features/organisasjon/organisasjon.queries";
import { SearchIcon } from "@fremtind/jkl-icons-react";
import { Loader } from "@fremtind/jkl-loader-react";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@fremtind/jkl-table-react";
import type { Organisasjon } from "@model/gen";

import "./OrganizationTable.scss";

export const OrganizationTable = () => {
    const { bruker } = useBruker();
    const [match, setMatch] = useState("");
    const { data } = useOrganizations();
    const navigate = useNavigate();
    const [rowLoading, setRowLoading] = useState(-1);

    useEffect(() => {
        track({
            hendelse: Trackingkey.SeListe,
            type: "bedrifter",
            antall: data ? data?.length : 0
        });
    }, [bruker, navigate, data]);

    const matchedOrgnummer = data?.filter(orgFilter).map((org) => org.organisasjonsnummer) ?? [];

    function orgFilter(org: Organisasjon) {
        const searchKeywords = [
            org.navn,
            org.organisasjonsnummer,
            toFormattedOrganisasjonsnummer(org.organisasjonsnummer)
        ];

        const matchWithoutSpaces = match.replaceAll(" ", "");

        const formatted = (Number(matchWithoutSpaces) ? matchWithoutSpaces : match).trim();

        return searchByRegex(searchKeywords, formatted);
    }

    return (
        <>
            <SearchField
                label="Søk"
                labelProps={{ srOnly: true }}
                dataTestautoid="velg-organisasjon-søk"
                action={{ icon: <SearchIcon />, label: "Søk", onClick: () => undefined }}
                placeholder="Navn eller org.nr"
                className="velg-organisasjon__søk"
                matchedCount={matchedOrgnummer.length}
                autoCorrect="false"
                spellCheck="false"
                onBlur={(e) =>
                    track({
                        hendelse: Trackingkey.Søk,
                        tekst: e.target.value,
                        treff: matchedOrgnummer.length,
                        tekstlengde: e.target.value.length
                    })
                }
                {...{ match, setMatch }}
            />

            {data?.filter((org) => matchedOrgnummer.includes(org.organisasjonsnummer)).length === 0 ? (
                <Typography subdued>Fant ingen virksomheter med navn eller organisasjonsnummer: {match}</Typography>
            ) : (
                <Table fullWidth data-testautoid="tabell-velg-organisasjon">
                    <TableHead>
                        <TableRow>
                            <TableHeader>Virksomhet</TableHeader>
                            <TableHeader className="velg-organisasjon__org-nummer-header w-[140px]">
                                Org. nr
                            </TableHeader>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data
                            ?.filter((org) => matchedOrgnummer.includes(org.organisasjonsnummer))
                            .map((org, index) => (
                                <TableRow
                                    key={org.organisasjonsnummer}
                                    role="link"
                                    aria-label={`Velg organisasjon ${org.navn}`}
                                    clickable={
                                        rowLoading === -1 || rowLoading === index
                                            ? {
                                                  onClick: () => {
                                                      setRowLoading(index);
                                                      navigate(`/bytt-organisasjon/${org.organisasjonsnummer}`);
                                                  }
                                              }
                                            : undefined
                                    }
                                >
                                    <TableCell className="velg-organisasjon__org-navn">
                                        <div className="velg-organisasjon__org-navn__content">
                                            {org.navn}
                                            {rowLoading === index && (
                                                <Loader textDescription={`Laster inn ${org.navn}`} />
                                            )}
                                        </div>
                                    </TableCell>
                                    <TableCell className="velg-organisasjon__org-nummer">
                                        {toFormattedOrganisasjonsnummer(org.organisasjonsnummer)}
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            )}
        </>
    );
};
