import React from "react";
import classNames from "classnames";
import { IHttpError } from "../../model/http.typer";
import { Feil } from "../Feil/Feil";
import { IngenBedriftFeil } from "../Feil/IngenBedriftFeil/IngenBedriftFeil";



export interface FeilWrapperProps {
    errorRedirectTo?: string;
    error?: IHttpError;
    illustrasjon?: boolean;
    fetchErrorMessage?: string;
    className?: string;
}

export const FeilWrapper: React.FC<React.PropsWithChildren<FeilWrapperProps>> = ({
    errorRedirectTo,
    error,
    illustrasjon = true,
    children,
    className,
    fetchErrorMessage
}) => {
    const classes = classNames(className);

    if (error) {
        return <Feil fetchErrorMessage={fetchErrorMessage} error={error} redirectOnOk={errorRedirectTo} illustrasjon={illustrasjon} />;
    }

    return (
        <div className={classes}>
            <IngenBedriftFeil />
            {children}
        </div>
    );
};
