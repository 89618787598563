import { AgreementOptionField, AgreementUpdatableField, AgreementDetails } from "@model/gen";
import { toNorwegianDateFormat } from "src/common/formatting";

export const MILEAGE_PER_YEAR_FIELD_NAME = "kjorelengde";
export const MILEAGE_FIELD_NAME = "kmStand";
export const MILEAGE_LAST_CHECKED_FIELD_NAME = "kmStandAvlest";

export const SUCCESSFULL_UPDATE_CODE = "INFO_AGREEMENT_UPDATED_SUCCESSFULLY";

type AvailableOptions = Array<AgreementOptionField>;

export const getInfoText = (agreementDetails: AgreementDetails | undefined) => {
const infoTexts = {
    agreementIsActive: "Endrer du avtalt kjørelengde midt i forsikringsåret, påvirker det prisen både forover og bakover i tid. Reduserer du kjørelengden får du penger tilbake, øker du den må du betale mer.",
    agreementIsRenewal: `Kjørelengden blir videreført til neste års avtale. Ønsker du bare å endre neste års kjørelengde, før ${toNorwegianDateFormat(agreementDetails?.endDate)}, kan du kontakte rådgiver, eller vente med å endre til avtalen er fornyet.`
    };

    return agreementDetails?.renewalPhase === "PRE_RENEWAL" || agreementDetails?.renewalPhase === "RENEWAL"  ? infoTexts.agreementIsRenewal : infoTexts.agreementIsActive;
};


export const updatesResponseToSelectItems = (options: AvailableOptions) => {
    const optionsAsSelectItems = options.map((option) => ({
        label: option.value,
        value: option.valueId
    }));

    const sorted = optionsAsSelectItems.sort((a, b) => Number(a.value) - Number(b.value));
    return sorted;
};

export const findField = (options: AgreementUpdatableField[], fieldName: string) => {
    return options.find((field) => field.semanticId === fieldName);
};

export const deriveMileageFromLabel = (label: string) => {
    const withoutSpaces = label.replace(/\s/g, "");
    const match = withoutSpaces.match(/(\d+)/);
    return match ? match[0] : "";
};
