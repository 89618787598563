import { toNorwegianDateFormat } from "src/common/formatting";

import { InfoMessageBox } from "@fremtind/jkl-message-box-react";
import type { AgreementDetails } from "@model/gen";

import { AgreementRenewalText } from "./AgreementRenewalText";

interface Props {
    agreementDetails: AgreementDetails;
}

export const AgreementRenewal = ({ agreementDetails }: Props) => {
    const inRenewalOrPreRenewalPhase =
        agreementDetails.renewalPhase === "RENEWAL" || agreementDetails.renewalPhase === "PRE_RENEWAL";

    if (!inRenewalOrPreRenewalPhase) return null;

    return (
        <div className={"max-w-3xl"}>
            <InfoMessageBox
                title={`Vi fornyer avtalen ${toNorwegianDateFormat(agreementDetails.nextVersion!.startDate)}`}
                fullWidth
                className="jkl-spacing-2xl--bottom"
                data-testid="agreement-renewal"
            >
                <AgreementRenewalText agreementDetails={agreementDetails} />
            </InfoMessageBox>
        </div>
    );
};
