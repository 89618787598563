import type React from "react";
import { useEffect, useRef, useState } from "react";

import { Link } from "react-router-dom";
import { Trackingkey, track } from "src/tracking";

import { useUpdatableFields } from "@features/agreement-updates/agreementupdates.queries";
import { MILEAGE_PER_YEAR_FIELD_NAME, findField } from "@features/agreement-updates/agreementupdates.utils";
import { AgreementChangesComposer } from "@features/agreements/components/AgreementChanges";
import { GetFormType } from "@features/agreements/components/AgreementChanges/GetFormType";
import { ChevronDownIcon } from "@fremtind/jkl-icons-react";
import { Menu, MenuDivider, MenuItem } from "@fremtind/jkl-menu-react";
import type { ModalInstance } from "@fremtind/jkl-modal-react";
import type { AgreementDetails } from "@model/gen";
import { isLagringsforsikring, agreementStatusIsOnHold} from "@features/agreements/avtale.utils";

import "./EditAgreementButton.scss";

interface Props {
    agreementDetails: AgreementDetails;
}

export const EditAgreementButton: React.FC<Props> = ({ agreementDetails }) => {
    const dialogRef = useRef<ModalInstance | null>();

    const [formAction, setFormAction] = useState<"cancel" | "change">("cancel");
    const [objectName, setObjectName] = useState("");

    const changeAbleAgreementForm = GetFormType(agreementDetails.productCode);

    const { data: updateableFieldsResponse } = useUpdatableFields(agreementDetails.id);

    const isMileagePerYearUpdateable =
        updateableFieldsResponse?.updatableFields &&
        !!findField(updateableFieldsResponse.updatableFields, MILEAGE_PER_YEAR_FIELD_NAME);

    const handleFormClick = (objectName: string) => {
        setObjectName(objectName);
        dialogRef.current?.show();
    };
    

    useEffect(() => {
        handleFormClick(objectName);
    }, [formAction, objectName]);

    // Avtaler på vent, eller i fornyelse skal ikke kunne endres.
    if ( agreementStatusIsOnHold(agreementDetails) || isLagringsforsikring(agreementDetails) ){
        return null;
    }

    return (
        <>
            <Menu
                initialPlacement="bottom-start"
                triggerElement={
                    <button
                        className="jkl-button jkl-button--secondary contextual-menu-button"
                        type="button"
                        onClickCapture={() => {
                            track({
                                hendelse: Trackingkey.Knappetrykk,
                                knappeId: "endre",
                                dropdown: true
                            });
                        }}
                    >
                        Endre{" "}
                        <span>
                            <ChevronDownIcon className="flex h-fit ml-2" />
                        </span>{" "}
                    </button>
                }
            >
                <MenuItem
                    as={Link}
                    to={`/forsikringer/${agreementDetails.id}/administrer/kilometerstand`}
                    className="agreement-changes-menu-item"
                    aria-label={`Bestill endring på ${agreementDetails.objects[0].name}`}
                >
                    Kilometerstand
                </MenuItem>
                {isMileagePerYearUpdateable && (
                    <MenuItem
                        as={Link}
                        type="link"
                        to={`/forsikringer/${agreementDetails.id}/administrer/kjorelengde`}
                        className="agreement-changes-menu-item"
                        aria-label={`Bestill endring på ${agreementDetails.objects[0].name}`}
                    >
                        Kjørelengde
                    </MenuItem>
                )}
                <MenuDivider />
                <MenuItem
                    type="button"
                    className="agreement-changes-menu-item"
                    aria-label={`Avslutt ${agreementDetails.objects[0].name}`}
                    onClick={() => {
                        handleFormClick(agreementDetails.objects[0].name!);
                        track({
                            hendelse: Trackingkey.Knappetrykk,
                            knappeId: "avslutt avtale"
                        });
                        if (formAction === "change") {
                            setFormAction("cancel");
                        } else {
                            dialogRef.current?.show();
                        }
                    }}
                >
                    Avslutt forsikring
                </MenuItem>
            </Menu>
            {changeAbleAgreementForm && (
                <AgreementChangesComposer
                    chosenObject={agreementDetails.objects[0].name!}
                    formAction={formAction}
                    formType={changeAbleAgreementForm}
                    avtaleDetaljer={agreementDetails}
                    dialogRef={dialogRef}
                />
            )}
        </>
    );
};
