import { useBruker } from "@features/bruker/queries";

import { useInvoiceReminders } from "../../invoice.queries";
import { InvoiceReminder } from "./InvoiceReminder";

export const InvoiceReminderDataWrapper = () => {
    const { data } = useInvoiceReminders();
    const { bruker } = useBruker();

    if (!bruker?.valgtOrganisasjon) {
        return null;
    }
    if (!data) {
        return null;
    }
    return  <div className="mb-64 md:mb-104"><InvoiceReminder invoiceReminders={data?.entity.invoiceReminders ?? []} /></div>;
};
