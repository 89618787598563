export type KundeFeilmelding = {
    title: string;
    message: string;
};

export const feilkoder = {
    forsikring: {
        bm: {
            avtale: {
                paris: {
                    nede: {
                        title: "Visningen av forsikringsavtaler kan være mangelfull",
                        message: "Du kan prøve på nytt eller komme tilbake senere for å se om vi har fikset problemet."
                    }
                },
                nice: {
                    nede: {
                        title: "Visningen av forsikringsavtaler kan være mangelfull",
                        message: "Du kan prøve på nytt eller komme tilbake senere for å se om vi har fikset problemet."
                    }
                },
                prolife: {
                    nede: {
                        title: "Visningen av forsikringsavtaler kan være mangelfull",
                        message: "Du kan prøve på nytt eller komme tilbake senere for å se om vi har fikset problemet."
                    }
                },
                alle: {
                    nede: {
                        title: "Vi klarer ikke å vise forsikringsavtalene dine",
                        message: "Du kan prøve på nytt eller komme tilbake senere for å se om vi har fikset problemet."
                    }
                }
            },
            avtaledetaljer: {
                paris: {
                    nede: {
                        title: "Vi klarer ikke å vise avtaledetaljene dine",
                        message: "Du kan prøve på nytt eller komme tilbake senere for å se om vi har fikset problemet."
                    }
                },
                nice: {
                    nede: {
                        title: "Vi klarer ikke å vise avtaledetaljene dine",
                        message: "Du kan prøve på nytt eller komme tilbake senere for å se om vi har fikset problemet."
                    }
                },
                prolife: {
                    nede: {
                        title: "Vi klarer ikke å vise avtaledetaljene dine",
                        message: "Du kan prøve på nytt eller komme tilbake senere for å se om vi har fikset problemet."
                    }
                }
            },
            ansatte: {
                paris: {
                    nede: {
                        title: "Listen med ansatte kan være mangelfull eller mangle noen avtaler",
                        message: "Du kan prøve på nytt eller komme tilbake senere for å se om vi har fikset problemet."
                    }
                },
                nice: {
                    nede: {
                        title: "Listen med ansatte kan være mangelfull eller mangle noen avtaler",
                        message: "Du kan prøve på nytt eller komme tilbake senere for å se om vi har fikset problemet."
                    }
                },
                ask: {
                    nede: {
                        title: "Listen med ansatte kan være mangelfull eller mangle noen avtaler",
                        message: "Du kan prøve på nytt eller komme tilbake senere for å se om vi har fikset problemet."
                    }
                },
                alle: {
                    nede: {
                        title: "Vi klarer ikke å vise ansattlisten din",
                        message: "Du kan prøve på nytt eller komme tilbake senere for å se om vi har fikset problemet."
                    }
                },
                detaljer: {
                    title: "Listen med ansatte kan være mangelfull eller mangle noen avtaler",
                    message: "Du kan prøve på nytt eller komme tilbake senere for å se om vi har fikset problemet."
                }
            },
            medlemmer: {
                paris: {
                    nede: {
                        title: "Listen med medlemmer kan være mangelfull eller mangle noen avtaler",
                        message: "Du kan prøve på nytt eller komme tilbake senere for å se om vi har fikset problemet."
                    }
                },
                nice: {
                    nede: {
                        title: "Listen med medlemmer kan være mangelfull eller mangle noen avtaler",
                        message: "Du kan prøve på nytt eller komme tilbake senere for å se om vi har fikset problemet."
                    }
                },
                ask: {
                    nede: {
                        title: "Listen med medlemmer kan være mangelfull eller mangle noen avtaler",
                        message: "Du kan prøve på nytt eller komme tilbake senere for å se om vi har fikset problemet."
                    }
                },
                alle: {
                    nede: {
                        title: "Vi klarer ikke å vise ansattlisten din",
                        message: "Du kan prøve på nytt eller komme tilbake senere for å se om vi har fikset problemet."
                    }
                },
                detaljer: {
                    title: "Listen med medlemmer kan være mangelfull eller mangle noen avtaler",
                    message: "Du kan prøve på nytt eller komme tilbake senere for å se om vi har fikset problemet."
                }
            },
            dokumenter: {
                nede: {
                    title: "Vi klarer ikke å vise dokumentene dine",
                    message: "Du kan prøve på nytt eller komme tilbake senere for å se om vi har fikset problemet."
                }
            },
            faktura: {
                gruppeliv: {
                    nede: {
                        title: "Listen med fakturaer kan være mangelfull",
                        message: "Du kan prøve på nytt eller komme tilbake senere for å se om vi har fikset problemet."
                    }
                },
                nice: {
                    nede: {
                        title: "Listen med fakturaer kan være mangelfull",
                        message: "Du kan prøve på nytt eller komme tilbake senere for å se om vi har fikset problemet."
                    }
                },
                prolife: {
                    nede: {
                        title: "Listen med fakturaer kan være mangelfull",
                        message: "Du kan prøve på nytt eller komme tilbake senere for å se om vi har fikset problemet."
                    }
                },
                avtale: {
                    nede: {
                        title: "Listen med fakturaer kan være mangelfull",
                        message: "Du kan prøve på nytt eller komme tilbake senere for å se om vi har fikset problemet."
                    }
                },
                foreningforbund: {
                    nede: {
                        title: "Listen med fakturaer kan være mangelfull",
                        message: "Du kan prøve på nytt eller komme tilbake senere for å se om vi har fikset problemet."
                    }
                },
                bedrift: {
                    nede: {
                        title: "Listen med fakturaer kan være mangelfull",
                        message: "Du kan prøve på nytt eller komme tilbake senere for å se om vi har fikset problemet."
                    }
                },
                dokumentmeta: {
                    nede: {
                        title: "Listen med fakturaer kan være mangelfull",
                        message: "Du kan prøve på nytt eller komme tilbake senere for å se om vi har fikset problemet."
                    }
                },
                alle: {
                    nede: {
                        title: "Vi klarer ikke å vise fakturaene dine",
                        message: "Du kan prøve på nytt eller komme tilbake senere for å se om vi har fikset problemet."
                    }
                }
            },
            samtykke: {
                oppdatereSamtykke: {
                    nede: {
                        title: "Vi klarte ikke å oppdatere samtykke",
                        message: "Du kan prøve på nytt eller komme tilbake senere for å se om vi har fikset problemet."
                    },
                    ingenTilgang: {
                        title: "Du har ikke tilgang til å oppdatere samtykke",
                        message: "Ta kontakt med daglig leder eller rådgiver din for å få tilgang."
                    }
                }
            },
            radgiver: {
                hopp: {
                    orgnummer: {
                        title: "",
                        message:
                            "Vi har problemer med å hente innhold for dette organisasjonsnummeret. Gå tilbake til Kundesøk BM og hopp til kundeløsning på nytt, eller prøv igjen senere."
                    },
                    ikkeTilgangRadgiver: {
                        title: "",
                        message: "Du har ikke tilgang til løsningen. Ta kontakt med din leder for å få tilgang."
                    },
                    manglerDistributor: {
                        title: "",
                        message: "Du kan prøve på nytt eller komme tilbake senere for å se om vi har fikset problemet."
                    },
                    manglerKonsesjon: {
                        title: "",
                        message: "Du kan prøve på nytt eller komme tilbake senere for å se om vi har fikset problemet."
                    },
                    matcherIkkeDistributor: {
                        title: "",
                        message: "Du kan prøve på nytt eller komme tilbake senere for å se om vi har fikset problemet."
                    },
                    ikkeTilgangInterneAvtaler: {
                        title: "",
                        message:
                            "Du har ikke tilgang til virksomheter med interne avtaler. Ta kontakt med din leder for å få tilgang."
                    }
                }
            },
            filvisning: {
                default: {
                    title: "Oi, dette gikk ikke",
                    message: "Vi klarer ikke hente dokumentet ditt. Lukk denne fanen og prøv igjen."
                }
            },
            oppdatering: {
                feilet: {
                    title: "Noe gikk galt hos oss",
                    message:
                        "Vi klarte ikke å gjennomføre oppdateringen. Du kan prøve på nytt, eller komme tilbake senere for å se om vi har fikset problemet."
                }
            },
            organisasjon: {
                tilgang: {
                    til_org_nr: {
                        inaktiv: {
                            title: "Vi kan ikke gi tilgang til organisasjonen",
                            message:
                                "Organisasjonen er ikke aktiv i Enhetsregisteret. Dette kan for eksempel være fordi organisasjonen ikke finnes mer eller har gått konkurs."
                        },
                        eksisterer: {
                            ikke: {
                                title: "Organisasjonsnummeret finnes ikke",
                                message: "Har det lurt seg inn en skrivefeil?"
                            }
                        }
                    },
                    eksisterer: {
                        title: "Virksomheten har allerede tilgang",
                        message: "Du kan ikke gi tilgang til samme virksomhet flere ganger."
                    }
                }
            },
            felles: {
                constraint: {
                    organisasjonsnummer: {
                        ugyldig_organisasjonsnummer: {
                            title: "Organisasjonsnummeret finnes ikke",
                            message: "Har det lurt seg inn en skrivefeil?"
                        }
                    }
                }
            }
        }
    },
    default: {
        title: "Visningen kan være mangelfull",
        message: "Du kan prøve på nytt eller komme tilbake senere for å se om vi har fikset problemet."
    }
};
