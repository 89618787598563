import { brukerHarTilgang } from "src/common/rolle";
import { BrukerRolle } from "src/model/bruker.typer";
import { IHttpError } from "src/model/http.typer";

import { http } from "@features/core";
import { FbmResponseEntityVoid, SamtykkeOgKontaktResponse } from "@model/gen";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { useBruker } from "../bruker/queries";

export type UpdateReservationQuery = {
    reservation: boolean;
    dataSharing: boolean;
    email: string;
};

const samtykkeQueryKey = ["samtykke"];

// FETCH SAMTYKKE //
const fetchReservation = async () => {
    const { entity, feilmelding } = await http.getEntity<SamtykkeOgKontaktResponse>("samtykke");
    return {
        ...entity,
        feilmelding
    };
};

export const useReservation = () => {
    const { bruker } = useBruker();
    return useQuery({
        queryKey: samtykkeQueryKey,
        queryFn: fetchReservation,
        enabled:
            !!bruker?.gjeldendeOrganisasjonsnummer &&
            brukerHarTilgang(bruker, [BrukerRolle.ADMINISTRATOR, BrukerRolle.RADGIVER, BrukerRolle.RADGIVER_DNB]),
        refetchOnWindowFocus: "always",
        staleTime: Infinity
    });
};
// UPDATE SAMTYKKE //
const updateReservation = async ({ reservation, dataSharing, email }: UpdateReservationQuery) => {
    return http.post<FbmResponseEntityVoid>("samtykke", {
        kontaktInfo: reservation ? undefined : { epost: email },
        samtykke: {
            ekunde: {
                kundeSamtykkeGitt: !reservation
            },
            delingAvData: {
                kundeSamtykkeGitt: dataSharing
            }
        }
    });
};

export const useUpdateReservation = () => {
    const queryClient = useQueryClient();
    return useMutation<unknown, IHttpError, UpdateReservationQuery>({
        mutationFn: updateReservation,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: samtykkeQueryKey });
        }
    });
};
