import { ReactNode, useMemo, useRef, useState } from "react";

import { PrimaryButton } from "@fremtind/jkl-button-react";
import { formatFodselsnummer } from "@fremtind/jkl-formatters-util";
import { InfoMessageBox } from "@fremtind/jkl-message-box-react";
import { Organisasjon } from "@model/gen";

import { toFormattedOrganisasjonsnummer } from "../../../../common/formatting";
import { PrimaryLinkButton, SecondaryLinkButton } from "../../../../components/Button";
import { ChooseFromList, ChooseFromListFormData, ChooseFromListProps } from "../../../../components/ChooseFromList";
import { Kvittering, KvitteringProps } from "../../../../components/Kvittering";
import { Typography } from "../../../../components/Typography";
import { Trackingkey, track } from "../../../../tracking";
import { PersonEntity, TilgangerTredjePart } from "../../models";
import { useAddTilgangerTredjePart } from "../../queries";
import "./TTPNyTilgang.scss";

const isBedrift = (subject: Subject): subject is Organisasjon => "organisasjonsnummer" in subject;

const getError = (subject: Subject) => {
    const subjectName = isBedrift(subject)
        ? { singular: "virksomhetsansvarlig", plural: "virksomhetsansvarlige" }
        : { singular: "virksomhet", plural: "virksomheter" };

    return {
        serverError: {
            title: "Oi, litt tekniske problemer her!",
            body: "Vi kunne ikke gi tilgang akkurat nå. Prøv en gang til eller kom tilbake senere."
        },
        userError: {
            title: `Velg minst én ${subjectName.singular}`,
            body: `Du må velge én eller flere ${subjectName.plural} for å kunne gå videre.`
        }
    };
};

const getKvitteringProps = (
    subject: Subject,
    savedEntities: Organisasjon[] | PersonEntity[]
): Omit<KvitteringProps, "children"> => {
    if (isBedrift(subject)) {
        savedEntities = savedEntities as PersonEntity[];

        return {
            heading: `Flott! ${savedEntities.length} ${savedEntities.length > 1 ? "nye" : "ny"} ${
                savedEntities.length > 1 ? "virksomhetsansvarlige" : "virksomhetsansvarlig"
            } på ${subject.navn}`,
            positives: savedEntities.map((kam) => `${kam.fornavn} ${kam.etternavn}`)
        };
    } else {
        savedEntities = savedEntities as Organisasjon[];

        return {
            heading: `Flott! ${subject.fornavn} ${subject.etternavn} kan nå se og endre:`,
            positives: savedEntities.map((bedrift) => bedrift.navn)
        };
    }
};

const getFormProps = (
    subject: Subject,
    options: Options,
    tilanger: TilgangerTredjePart
): Omit<ChooseFromListProps, "children" | "onSubmit" | "error"> => {
    if (isBedrift(subject)) {
        const kamer = options as PersonEntity[];

        return {
            title: `Hvem skal få tilgang til ${subject.navn}?`,
            instruction: "Velg én eller flere virksomhetsansvarlige",
            labelprops: { variant: "medium", srOnly: false },
            options: kamer.map((kam) => ({
                label: `${kam.fornavn} ${kam.etternavn}`,
                extraLabel: `(${formatFodselsnummer(kam.fodselsnummer)})`,
                value: kam.fodselsnummer
            })),
            withSearch: {
                placeholder: "Navn eller fødselsdato",
                noMatchText: "Ingen virksomhetsansvarlige passer søket ditt"
            },
            noOptionsContent: (
                <InfoMessageBox title="Infomelding">
                    <Typography className="jkl-spacing-l--bottom">
                        {tilanger.kamer.length ? (
                            <>
                                Alle dine registrerte virksomhetsansvarlige har tilgang til denne virksomheten. Ønsker du å
                                opprette en ny virksomhetsansvarlig?
                            </>
                        ) : (
                            <>
                                Du har ikke opprettet noen virksomhetsansvarlige. Velg{" "}
                                <i>&quot;Ny virksomhetsansvarlig&quot;</i> for å legge til en ny.
                            </>
                        )}
                    </Typography>
                    <PrimaryLinkButton to="/tilganger-andre-bedrifter/ny-bedriftsansvarlig">
                        Ny virksomhetsansvarlig
                    </PrimaryLinkButton>
                </InfoMessageBox>
            )
        };
    } else {
        const bedrifter = options as Organisasjon[];

        return {
            title: `Hvilke virksomheter skal ${subject.fornavn} ${subject.etternavn} få tilgang til?`,
            instruction: "Velg én eller flere virksomheter",
            labelprops: { srOnly: false, variant: "medium" },
            options: bedrifter.map((bedrift) => ({
                label: bedrift.navn,
                extraLabel: `(${toFormattedOrganisasjonsnummer(bedrift.organisasjonsnummer)})`,
                value: bedrift.organisasjonsnummer
            })),
            withSearch: {
                placeholder: "Navn eller org.nr. på virksomhet",
                noMatchText: "Ingen virksomheter passer søket ditt"
            },
            noOptionsContent: (
                <InfoMessageBox title="Infomelding">
                    {subject.fornavn} har tilgang til alle virksomhetene som har gitt dere tilgang. Savner du en virksomhet,
                    ta kontakt med daglig leder hos den virksomheten det gjelder.
                </InfoMessageBox>
            )
        };
    }
};

type Subject = Organisasjon | PersonEntity;
type Options = Organisasjon[] | PersonEntity[];

interface TTPNyTilgangProps {
    subject: Subject;
    options: Options;
    flytnavn: string;
    tilganger: TilgangerTredjePart;
    backButton?: ReactNode;
    doneLinkTo?: string;
}

export const TTPNyTilgang = (props: TTPNyTilgangProps) => {
    const [error, setError] = useState<{ title: string; body: string }>();
    const savedEntities = useRef<Organisasjon[] | PersonEntity[]>();
    const mutation = useAddTilgangerTredjePart();
    const formProps = useMemo(
        () => getFormProps(props.subject, props.options, props.tilganger),
        [props.options, props.subject, props.tilganger]
    );

    const handleSubmit = ({ checked }: ChooseFromListFormData) => {
        if (!checked || !checked.length) {
            setError(getError(props.subject).userError);

            return;
        }

        track({
            hendelse: Trackingkey.Skjemaflyt,
            flytnavn: props.flytnavn,
            handling: "neste",
            stegnavn: "nye tilganger",
            programmatisk: false
        });

        let data;
        let entities: Organisasjon[] | PersonEntity[];

        if ("organisasjonsnummer" in props.subject) {
            const kamer = props.options as PersonEntity[];

            entities = kamer.filter((kam) => checked.includes(kam.fodselsnummer));
            data = {
                kamer: entities,
                tilOrganisasjoner: [props.subject]
            };
        } else {
            const bedrifter = props.options as Organisasjon[];

            entities = bedrifter.filter((bedrift) => checked.includes(bedrift.organisasjonsnummer));
            data = {
                kamer: [props.subject],
                tilOrganisasjoner: entities
            };
        }

        mutation.mutate(data, {
            onSuccess: () => {
                savedEntities.current = entities;
            },
            onError: () => {
                setError(getError(props.subject).serverError);
            }
        });
    };

    if (mutation.isSuccess && savedEntities.current) {
        return (
            <div>
                <Kvittering {...getKvitteringProps(props.subject, savedEntities.current)} spacedBottom>
                    <SecondaryLinkButton
                        to={
                            props.doneLinkTo ||
                            `/tilganger-andre-bedrifter${isBedrift(props.subject) ? "" : "?tabIndex=1"}`
                        }
                        onClick={() => {
                            track({
                                hendelse: Trackingkey.Skjemaflyt,
                                flytnavn: props.flytnavn,
                                handling: "ferdig",
                                stegnavn: "kvittering",
                                programmatisk: false,
                                omstart: false
                            });
                        }}
                    >
                        Ferdig
                    </SecondaryLinkButton>
                </Kvittering>
            </div>
        );
    }

    return (
        <ChooseFromList multiple selectAll {...formProps} onSubmit={handleSubmit} error={error}>
            <PrimaryButton loader={{ showLoader: mutation.isPending, textDescription: "lagrer nye tilganger" }}>
                Lagre
            </PrimaryButton>
            {props.backButton || (
                <SecondaryLinkButton
                    to={`/tilganger-andre-bedrifter${isBedrift(props.subject) ? "" : "?tabIndex=1"}`}
                    type="secondary"
                    onClick={() => {
                        track({
                            hendelse: Trackingkey.Skjemaflyt,
                            flytnavn: props.flytnavn,
                            handling: "avbryt",
                            stegnavn: "nye tilganger",
                            programmatisk: false
                        });
                    }}
                >
                    Avbryt
                </SecondaryLinkButton>
            )}
        </ChooseFromList>
    );
};
