import { formatValuta } from "@fremtind/jkl-formatters-util";
import { AgreementDetails } from "@model/gen";
import { Link } from "react-router-dom";
import { Link as JklLink } from "@fremtind/jkl-core";

interface Props  {
    avtale: AgreementDetails
}

export const FornyelseBil = ({avtale} : Props) => {
    return (
        <>
            Prisen blir endret til {avtale.nextVersion?.annualPremium && formatValuta(avtale.nextVersion.annualPremium)}. Det er viktig at du sjekker om kjørelengden er riktig. Ønsker du å gjøre endringer, på for eksempel dekning og/eller egenandel,{" "}
            <Link to="/kontakt-oss" className="jkl-link">
            ta kontakt med rådgiver
            </Link>.{" "}
            Her ser du <JklLink rel="noopener noreferrer" className="jkl-link" href={`/bedrift/api/dokumenter/avtale/pdf/${avtale.id}/fornyelse`} target="_blank"> nytt avtaledokument</JklLink>.
        </>
    );
};
