import { LenkeId, useExternalLinks } from "src/common/lenker";
import { Trackingkey, track } from "src/tracking";

import { NavCard } from "@fremtind/jkl-card-react";

import "./RelatedServicesList.scss";

export const RelatedServicesList = () => {
    const privateInsuranceLink = useExternalLinks().getLinkByLinkId(LenkeId.privatforsikring)?.href;
    const pensionLink = useExternalLinks().getLinkByLinkId(LenkeId.pensjon)?.href;
    const newClaimLink = useExternalLinks().getLinkByLinkId(LenkeId.meldSkade)?.href;

    return (
        <nav className="related-serivices-list">
            <ul>
                {pensionLink && (
                    <li>
                        <NavCard
                            title="Pensjon"
                            href={pensionLink}
                            target="_blank"
                            onClick={() => {
                                track({
                                    hendelse: Trackingkey.LenkeTrykk,
                                    lenkeId: "pensjon"
                                });
                            }}
                        />
                    </li>
                )}
                {privateInsuranceLink && (
                    <li>
                        <NavCard
                            title="Private forsikringer"
                            href={privateInsuranceLink}
                            target="_blank"
                            onClick={() => {
                                track({
                                    hendelse: Trackingkey.LenkeTrykk,
                                    lenkeId: "privatforsikring"
                                });
                            }}
                        />
                    </li>
                )}
                {newClaimLink && (
                    <li>
                        <NavCard
                            title="Meld skade"
                            href={newClaimLink}
                            target="_blank"
                            onClick={() => {
                                track({
                                    hendelse: Trackingkey.LenkeTrykk,
                                    lenkeId: "meld-skade"
                                });
                            }}
                        />
                    </li>
                )}
            </ul>
        </nav>
    );
};
