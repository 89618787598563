import { toNorwegianDateFormat } from "src/common/formatting";

import { formatNumber, formatValuta } from "@fremtind/jkl-formatters-util";
import {
    AgreementDetails,
    AgreementField,
    AgreementOverview,
    EmployeeBrochureAgreement,
    EmployeeBrochureProduct
} from "@model/gen";

import { model } from "./avtale.module";

export interface EmployeeBrochureAgreement2 {
    productId: string | undefined;
    productName: string | undefined;
    objects:
        | Array<{
              agreementId: string;
              objectId: string;
              objectName?: string;
          }>
        | [];
}

export function transformAnsattbrosjyreData(data: EmployeeBrochureProduct[]): EmployeeBrochureAgreement[] {
    return data.map((produkt) => {
        const avtaleGrupper = produkt.agreements?.reduce<
            { agreementId?: string; objectId?: string; objectName?: string }[]
        >((acc, avtale) => {
            if (!avtale.objects) {
                return acc;
            }
            const grupper = avtale.objects.map((gruppe) => ({
                agreementId: avtale.agreementId,
                objectId: gruppe.objectId,
                objectName: gruppe.objectName
            }));

            return [...acc, ...grupper];
        }, []);

        return {
            productId: produkt.productId,
            productName: produkt.productName,
            objects: avtaleGrupper
        };
    });
}

export function formatValue(value: string, type: AgreementField["type"]): string {
    switch (type) {
        case "BOOLEAN": {
            return value === "true" ? "Ja" : "Nei";
        }
        case "DATE": {
            return toNorwegianDateFormat(value) as string;
        }
        case "TREATMENT": {
            return `${value} behandlinger`;
        }
        case "GVALUE": {
            // 12.0 -> 12 G
            // 6.5 -> 6,5 G
            return `${value
                .split(".")
                .filter((v, i) => i === 0 || Number(v) > 0)
                .join(",")} G`;
        }
        case "CURRENCY":
            return formatValuta(value);

        case "NUMBER":
            return formatNumber(value) as string;

        case "STRING":
        default:
            return value;
    }
}

export function asAvtaleProduktId(produktId: string): model.AvtaleProduktID {
    type AvtaleProduktID = keyof typeof model.AvtaleProduktID;
    const enumReverseValue = Object.keys(model.AvtaleProduktID).find(
        (enumKey) => model.AvtaleProduktID[enumKey as AvtaleProduktID] === produktId
    );

    return model.AvtaleProduktID[enumReverseValue as AvtaleProduktID];
}

export function isFlateAvtale(produktId: model.AvtaleProduktID): boolean {
    return [
        model.AvtaleProduktID.FLATE_ARBEIDSMASKIN,
        model.AvtaleProduktID.FLATE_BIL,
        model.AvtaleProduktID.FLATE_LASTEBIL,
        model.AvtaleProduktID.FLATE_TILHENGER
    ].includes(produktId);
}

export function isParisAvtale(produktId: model.AvtaleProduktID) {
    return [
        model.AvtaleProduktID.PERSONALGRUPPELIV,
        model.AvtaleProduktID.FORENINGSGRUPPELIV,
        model.AvtaleProduktID.FORBUNDSGRUPPELIV
    ].includes(produktId);
}

export const harPersonalGruppelivAvtale = (avtaler: model.Avtale[]) =>
    avtaler.some((avtale) => avtale.produktId === model.AvtaleProduktID.PERSONALGRUPPELIV);

export function includesAnsattbrosjyreAvtaler(avtaler: Array<AgreementOverview>) {
    const viableProducts = [
        model.AvtaleProduktID.PERSONALGRUPPELIV,
        model.AvtaleProduktID.REISE_NERING,
        model.AvtaleProduktID.ANNEN_SYKDOM,
        model.AvtaleProduktID.YRKESSKADE,
        model.AvtaleProduktID.KOLLEKTIV_ULYKKE_BEDRIFT,
        model.AvtaleProduktID.BEHANDLINGSFORSIKRING
    ];

    return avtaler.some((avtale) => viableProducts.includes(asAvtaleProduktId(avtale.productCode)));
}

export const agreementStatusIsRenewal = (agreement?: AgreementOverview, agreementDetails?: AgreementDetails ) => {
    return (agreement?.renewalPhase === "RENEWAL" || agreement?.renewalPhase === "PRE_RENEWAL") || agreementDetails?.status === "RENEWAL";
};

export const agreementStatusIsOnHold = (agreement?: AgreementOverview, agreementDetails?: AgreementDetails) => {
    return agreement?.status === "ACCEPT_ON_HOLD" || agreementDetails?.status === "ACCEPT_ON_HOLD";
};

export const agreementStatusIsAccept = (agreement?: AgreementOverview, agreementDetails?: AgreementDetails) => {
    return agreement?.status === "ACCEPT" || agreementDetails?.status === "ACCEPT";
};

export const isLagringsforsikring = (agreementDetails?: AgreementDetails) => {
    return agreementDetails?.stateDescription === "Lagringsforsikring";
};

export const isMedlemAgreement = (avtale: AgreementOverview) => {
    return avtale.category === "FORENING_FORBUND";
};

export const isAnsattAvtale = (avtale: model.Avtale) => {
    return avtale.category === "ANSATT";
};
