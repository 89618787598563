import { BMRoute } from "src/sider";
import { Administrerforsikring } from "src/sider/forsikringer/administrer";

import { agreementUpdateRoutes } from "@features/agreement-updates/agreementupdates.routes";

import { AgreementDetailsComposer, loader as AgreementDetailsLoader } from "./views/AgreementDetails";
import { AgreementsOverview, loader as AgreementsOverviewLoader } from "./views/AgreementsOverview";
import { Vehicles, loader as VehiclesLoader } from "./views/Vehicles";

export const agreementRoutes: BMRoute[] = [
    {
        path: "/forsikringer",
        children: [
            {
                loader: AgreementsOverviewLoader,
                element: (
                    <>
                        {" "}
                        <AgreementsOverview />
                    </>
                ),
                breadcrumbLabel: "Forsikringer",
                index: true
            },
            {
                path: ":agreementId",
                children: [
                    {
                        element: <AgreementDetailsComposer />,
                        loader: AgreementDetailsLoader,
                        index: true,
                        breadcrumbLabel: "Avtaledetaljer"
                    },
                    ...agreementUpdateRoutes
                ]
            },
            {
                path: "administrer",
                element: <Administrerforsikring />,
                breadcrumbLabel: "Administrer forsikring"
            },
            {
                path: "kjoretoy",
                children: [
                    {
                        index: true,
                        element: <Vehicles />,
                        loader: VehiclesLoader,
                        breadcrumbLabel: "Kjøretøy"
                    }
                ]
            }
        ]
    }
];
