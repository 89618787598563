import { useContext } from "react";

import ThemeContext from "src/context/ThemeContext";
import { Distributor } from "src/model/theme.typer";
import { Trackingkey, track } from "src/tracking";

import { useAgreementList } from "@features/agreements/agreements.queries";
import { AvtaleProduktID } from "@features/agreements/avtale.model";
import { NavCard } from "@fremtind/jkl-card-react";

import { useAvailableProfessions } from "./queries";

export const HomePageCTA: React.FC = () => {
    const { distributor } = useContext(ThemeContext);
    const isDNBCustomer = distributor === Distributor.DNB;

    const { data } = useAvailableProfessions();
    const hasProfessions = !!data?.professions?.length;

    const { data: agreementsData } = useAgreementList();

    const hasYrkesskade = agreementsData?.agreements.some(
        (agreement) => agreement.productCode === AvtaleProduktID.YRKESSKADE
    );

    if (isDNBCustomer || !hasProfessions || hasYrkesskade) return null;

    return (
        <NavCard
            className="cta-banner"
            title="Yrkesskadeforsikring"
            image={{
                src: "/bedrift/static/images/inngangkjop.png",
                alt: "Person på togplatform"
            }}
            href="kjop/yrkesskade"
            description="Forsikringen er lovpålagt for alle ansatte, også for de som jobber deltid, midlertidig og sesongarbeidere."
            onClick={() => {
                track({
                    hendelse: Trackingkey.LenkeTrykk,
                    lenkeId: "kjop-forsikring-yrkesskade"
                });
            }}
        />
    );
};
