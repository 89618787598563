import React from "react";

import { Await, useLoaderData } from "react-router-dom";
import { queryClient } from "src/common/queryClient";
import { Bruker } from "src/model/bruker.typer";

import { Side, SideHeader, SideInnhold } from "@components/Side";
import { brukerQueryKey, fetchBruker } from "@features/bruker/queries";
import { EntityResponse } from "@features/core/http";
import { InvoiceDto } from "@model/gen";

import { invoiceListQuery } from "../../invoice.queries";
import "./InvoiceOverview.scss";
import { InvoiceOverviewComposer } from "./InvoiceOverviewComposer";
import { InvoiceOverviewLoader } from "./InvoiceOverviewLoader";

export const loader = async () => {
    const bruker = await queryClient.ensureQueryData<Bruker>({ queryKey: brukerQueryKey, queryFn: fetchBruker });

    if (bruker && bruker.gjeldendeOrganisasjonsnummer) {
        return {
            invoices: invoiceListQuery(bruker.gjeldendeOrganisasjonsnummer)
        };
    }

    return Promise.resolve();
};

export const InvoiceOverview = () => {
    const { invoices } = useLoaderData() as { invoices: EntityResponse<InvoiceDto[]> };

    return (
        <Side className="bm-invoice-overview">
            <SideHeader
                title="Faktura og betaling"
                lead="Her har du oversikt over fakturaene dine fra Fremtind. Fakturaene er alltid tilgjengelige, og du kan laste dem ned eller eksportere dem til Excel."
            />
            <SideInnhold>
                <React.Suspense fallback={<InvoiceOverviewLoader />}>
                    <Await resolve={invoices}>
                        <InvoiceOverviewComposer />
                    </Await>
                </React.Suspense>
            </SideInnhold>
        </Side>
    );
};
