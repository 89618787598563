import { type FC, useContext } from "react";

import ThemeContext from "src/context/ThemeContext";

import { PrimaryLinkButton } from "@components/Button";
import { Typography } from "@components/Typography";
import { useIsManaged } from "@features/bruker/queries";
import { InfoBlock, TaskCard } from "@fremtind/jkl-card-react";
import { InfoTag } from "@fremtind/jkl-tag-react";
import type { GrunndataContactInfoDto } from "@model/gen";

import "./GrunndataDocumentsSection.scss";

interface Props {
    consent?: boolean;
    email: GrunndataContactInfoDto["documentEmail"];
}

export const GrunndataDocumentsSection: FC<Props> = ({ consent, email }) => {
    const { distributor } = useContext(ThemeContext);
    const isManaged = useIsManaged();

    if (distributor === "dnb" || isManaged) return null;

    if (!consent) {
        return (
            <section className="grunndata-documents-reciever">
                <header>
                    <Typography variant="heading-2">Mottaker av forsikringsdokumenter</Typography>
                    <Typography>
                        Hvis du vil ha forsikringsdokumentene digitalt må du samtykke til digital kommunikasjon.
                        Forsikringsdokumenter er for eksempel avtaledokumenter ved fornyelse.
                    </Typography>
                </header>
                <TaskCard className="grunndata-documents-reciever__card">
                    <Typography className="grunndata-documents-reciever__card__title">
                        Du mottar forsikringsdokumentene i posten
                    </Typography>
                    <InfoBlock>
                        <PrimaryLinkButton to={"/samtykker"} className="grunndata-documents-reciever__card__button">
                            Få dokumentene digitalt
                        </PrimaryLinkButton>
                    </InfoBlock>
                </TaskCard>
            </section>
        );
    }

    if (!email) {
        return (
            <section className="grunndata-documents-reciever">
                <header>
                    <Typography variant="heading-2">Mottaker av forsikringsdokumenter</Typography>
                    <Typography>
                        Hvis du vil ha forsikringsdokumentene digitalt må du samtykke til digital kommunikasjon.
                        Forsikringsdokumenter er for eksempel avtaledokumenter ved fornyelse.
                    </Typography>
                </header>
                <TaskCard className="grunndata-documents-reciever__card">
                    <InfoTag className="mb-64">E-postadresse mangler</InfoTag>
                    <Typography className="grunndata-documents-reciever__card__title">
                        Du mottar forsikringsdokumentene i posten
                    </Typography>
                    <InfoBlock>
                        <PrimaryLinkButton to={"/samtykker"} className="grunndata-documents-reciever__card__button">
                            Registrer en e-postadresse
                        </PrimaryLinkButton>
                    </InfoBlock>
                </TaskCard>
            </section>
        );
    }

    return (
        <section className="grunndata-documents-reciever">
            <header>
                <Typography variant="heading-2">Mottaker av forsikringsdokumenter</Typography>
                <Typography>
                    Hvis du vil motta dokumentene i posten kan du endre samtykket. Forsikringsdokumenter er for eksempel
                    avtaledokumenter ved fornyelse.
                </Typography>
            </header>
            <TaskCard className="grunndata-documents-reciever__card">
                <InfoTag>Du mottar forsikringsdokumentene digitalt</InfoTag>
                <Typography className="grunndata-documents-reciever__card__title">{email}</Typography>
                <InfoBlock>
                    <PrimaryLinkButton to={"/samtykker"} className="grunndata-documents-reciever__card__button">
                        Endre
                    </PrimaryLinkButton>
                </InfoBlock>
            </TaskCard>
        </section>
    );
};
