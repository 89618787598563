import { useEffect } from "react";

import { Trackingkey, track } from "src/tracking";

import { Side, SideHeader, SideInnhold } from "@components/Side";
import { useReservation } from "@features/reservation/reservation.queries";
import { ErrorMessageBox } from "@fremtind/jkl-message-box-react";

import { GrunndataDocumentsSection, GrunndataGoldenRecordsSection } from "../components";
import { useGetGrunndataContactInfo } from "../grunndataContactInfo.queries";
import "./GrunndataContactInfoView.scss";

export const GrunndataContactInfoView = () => {
    const grunndata = useGetGrunndataContactInfo();
    const reservation = useReservation();

    const grunndataDocsEmail = grunndata.data?.documentEmail;
    const grunndataGoldenRecords = grunndata.data?.goldenRecords;
    const hasConsented = reservation.data?.samtykke.ekunde?.kundeSamtykkeGitt;

    useEffect(() => {
        track({
            hendelse: Trackingkey.SeListe,
            type: "Golden Records (organisasjoner)",
            antall: grunndataGoldenRecords?.length ?? 0
        });
    }, [grunndataGoldenRecords]);

    return (
        <Side>
            <SideHeader title="Kontakter" lead="Her ser du kontaktpunktene som står oppført for din virksomhet." />
            <SideInnhold
                loadingText="Laster inn kontaktpersonene dine"
                className="grunndata-contact-view"
                query={grunndata}
            >
                {grunndata.isError && <ErrorMessageBox>Klarte ikke hente samtykke</ErrorMessageBox>}
                {grunndata.data && (
                    <>
                        <GrunndataDocumentsSection email={grunndataDocsEmail} consent={hasConsented} />
                        <GrunndataGoldenRecordsSection
                            goldenRecords={grunndataGoldenRecords}
                            error={grunndata.data?.klientFeilmeldinger}
                        />
                    </>
                )}
            </SideInnhold>
        </Side>
    );
};
