import { useContext, useEffect, useState } from "react";

import classnames from "classnames";
import { Outlet, useLocation } from "react-router-dom";

import * as Sentry from "@sentry/react";
import { useOrganizations } from "@features/organisasjon/organisasjon.queries";
import { CookieConsent, useCookieConsent } from "@fremtind/jkl-cookie-consent-react";
import { Link } from "@fremtind/jkl-core";
import { Loader } from "@fremtind/jkl-loader-react";
import { Logo } from "@fremtind/jkl-logo-react";

import { Flag, useFeatureflag } from "./common/featureflag";
import { useEnsureSamtykke } from "./common/hooks";
import { generateSelector } from "./common/utils";
import { Feil } from "./components/Feil";
import { SideMeny } from "./components/Meny";
import ThemeContext from "./context/ThemeContext";
import { useScreen } from "./context/UseScreenContext";
import { useBruker } from "./features/bruker/queries";
import { BrukerRolle } from "./model/bruker.typer";
import { FeilsideOppgradering } from "./sider/FeilsideOppgradering";
import { Trackingkey, initTracking, optInTracking, optOutTracking, track, useNavigationTracking } from "./tracking";
import { MixpanelBruker } from "./tracking/mixpanel";
import "../index.css";

const App = () => {
    const { data: organizations } = useOrganizations();
    const organizationList = organizations?.map((org) => org.organisasjonsnummer) ?? [];
    const { bruker, error } = useBruker();
    const [isTracking, setIsTracking] = useState(false);
    const { BrandHeader, distributor } = useContext(ThemeContext);
    const { pathname } = useLocation();
    const { isMediumDevice, isSmallDevice } = useScreen();
    useEnsureSamtykke();
    const { openConsentModalWithSettings } = useCookieConsent();
    const feilsideTilgangsbasenIsEnabled = useFeatureflag(Flag.FEILSIDE_OPPGRADERING_TILGANGSBASEN_NEDE);
    const shouldShowSamtykke =
        bruker?.roller.includes(BrukerRolle.ADMINISTRATOR) || bruker?.roller.includes(BrukerRolle.RADGIVER);
    useNavigationTracking(isTracking);

    const mixpanelBruker: MixpanelBruker = { ...bruker!, organizations: organizationList };
    const isBedriftvelgerPath = pathname === "/";

    useEffect(() => {
        if (bruker?.trackingDetails.mixpanelProjectKey) {
            initTracking(bruker);
            setIsTracking(true);
        }
    }, [bruker]);

    useEffect(() => {
        const copyHandler = (event: ClipboardEvent) => {
            const selection = document.getSelection()?.toString() ?? "";
            const target = event.target as HTMLElement;
            const domNode = generateSelector(target);

            track({ hendelse: Trackingkey.Kopier, "dom-node": domNode, tekst: selection, side: pathname });
        };

        if (isTracking) {
            document.body.addEventListener("copy", copyHandler);
        }

        return () => document.body.removeEventListener("copy", copyHandler);
    }, [isTracking, pathname]);

    const wrapperClassname = classnames("page__wrapper", {
        "page__wrapper--with-loader": !isTracking
    });

    if (error) {
        return <Feil error={error} />;
    }

    if (!isTracking) {
        return (
            <>
                <div className={wrapperClassname} id="page-wrapper">
                    <BrandHeader />

                    <main id="innhold">
                        <Loader textDescription="Logger inn" />
                    </main>

                    <footer className="footer jkl" data-theme="dark">
                        <div className="footer__logo-container">
                            <Logo />
                        </div>
                        <nav>
                            <Link
                                external={false}
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://w2.brreg.no/enhet/sok/detalj.jsp?orgnr=915651232"
                            >
                                Fremtind Forsikring
                            </Link>
                            <Link
                                external={false}
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://www.fremtind.no/personvern/"
                            >
                                Personvern og vilkår
                            </Link>
                        </nav>
                    </footer>
                </div>
            </>
        );
    }

    if (feilsideTilgangsbasenIsEnabled) {
        return (
            <>
                <div className={wrapperClassname} id="page-wrapper">
                    <a className="jkl-link jkl-sr-only jkl-sr-only--focusable" href={`/bedrift${pathname}#innhold`}>
                        Hopp til hovedinnhold
                    </a>
                    <BrandHeader />

                    <main>
                        {!isSmallDevice && !isMediumDevice && <BrandHeader />}

                        <FeilsideOppgradering />
                    </main>
                    <CookieConsent
                        blocking
                        onAccept={(v) => {
                            if (v.statistics === "accepted") {
                                optInTracking(mixpanelBruker!, distributor);
                            } else if (v.statistics === "denied") {
                                optOutTracking();
                            }
                        }}
                    />
                </div>
                <footer data-theme="dark">
                    <div className="footer jkl">
                        <div className="flex justify-between">
                            <nav>
                                <Link
                                    external={false}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://w2.brreg.no/enhet/sok/detalj.jsp?orgnr=915651232"
                                >
                                    Fremtind Forsikring
                                </Link>
                                <Link
                                    external={false}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://www.fremtind.no/personvern/"
                                >
                                    Personvern og vilkår
                                </Link>
                                <button onClick={() => openConsentModalWithSettings()} className="jkl-link">
                                    Bruk av informasjonskapsler
                                </button>
                            </nav>

                            <div className="footer__logo-container">
                                <Logo />
                            </div>
                        </div>
                    </div>
                </footer>
            </>
        );
    }

    return (
        <>
            <div className={wrapperClassname} id="page-wrapper">
                <header>
                    <a className="jkl-link jkl-sr-only jkl-sr-only--focusable" href={`/bedrift${pathname}#innhold`}>
                        Hopp til hovedinnhold
                    </a>
                    <BrandHeader />
                </header>
                {!isBedriftvelgerPath && <SideMeny />}
                <main>
                    <div id="innhold">
                        <Outlet />
                    </div>
                </main>
            </div>
            <footer data-testautoid="footer" data-theme="dark">
                <div className="footer jkl">
                    <div className="flex justify-between flex-wrap gap-40">
                        <nav>
                            {shouldShowSamtykke && (
                                <Link
                                    data-testautoid="footer-samtykke"
                                    href="/bedrift/samtykker"
                                    onClick={() => {
                                        track({
                                            hendelse: Trackingkey.LenkeTrykk,
                                            lenkeId: "samtykke",
                                            type: "footer"
                                        });
                                    }}
                                >
                                    Samtykke
                                </Link>
                            )}
                            <Link
                                external={false}
                                target="_blank"
                                data-testautoid="footer-fremtind"
                                rel="noopener noreferrer"
                                href="https://w2.brreg.no/enhet/sok/detalj.jsp?orgnr=915651232"
                                onClick={() => {
                                    track({
                                        hendelse: Trackingkey.LenkeTrykk,
                                        lenkeId: "Fremtind Brreg",
                                        type: "footer"
                                    });
                                }}
                            >
                                Fremtind Forsikring
                            </Link>
                            <Link
                                external={false}
                                target="_blank"
                                data-testautoid="footer-personvern"
                                rel="noopener noreferrer"
                                href="https://www.fremtind.no/personvern/"
                                onClick={() => {
                                    track({ hendelse: Trackingkey.LenkeTrykk, lenkeId: "personvern", type: "footer" });
                                }}
                            >
                                Personvern og vilkår
                            </Link>
                            <button
                                data-testautoid="footer-cookies"
                                onClick={() => {
                                    openConsentModalWithSettings();
                                    track({ hendelse: Trackingkey.LenkeTrykk, lenkeId: "cookies", type: "footer" });
                                }}
                                className="jkl-link"
                            >
                                Bruk av informasjonskapsler
                            </button>
                        </nav>

                        <div className="footer__logo-container">
                            <Logo />
                        </div>
                    </div>
                </div>
            </footer>
            <CookieConsent
                data-testautoid="footer-cookie-consent"
                blocking
                onAccept={(v) => {
                    if (v.statistics === "accepted") {
                        optInTracking(mixpanelBruker!, distributor);
                    } else if (v.statistics === "denied") {
                        optOutTracking();
                    }
                }}
            />
        </>
    );
};

export default Sentry.withProfiler(App, { name: "BM HA" });
