import { useEffect, useRef, useState } from "react";

import { searchByRegex } from "src/common/utils";
import { ExcelExportLink, ExportEndpoint } from "src/components/ExcelExportLink";
import { SearchField } from "src/components/SearchField";
import { Typography } from "src/components/Typography";
import { KundeFeilmelding } from "src/model/feilkoder.object";
import { Trackingkey, track } from "src/tracking";

import { AvtaleProduktID } from "@features/agreements/avtale.model";
import { model } from "@features/navneliste";
import { Checkbox } from "@fremtind/jkl-checkbox-react";
import { Select } from "@fremtind/jkl-select-react";
import { Table, TableBody, TableHead, TableHeader, TableRow } from "@fremtind/jkl-table-react";
import { UseQueryResult } from "@tanstack/react-query";

import { formatAvtalenummer } from "../../../../common/formatting";
import "./InsuredTable.scss";
import { InsuredTableRow } from "./InsuredTableRow";

type HeaderFilter = { header: string; direction: "asc" | "desc" };

const searchFilter = (match: string) => (insured: model.Person) => {
    if (!match) return true;

    const keywords = [
        `${insured.fornavn} ${insured.etternavn}`,
        `${insured.fornavn.split(" ")[0]} ${insured.etternavn}`,
        `${insured.fodselsnummer}`
    ];

    const found = searchByRegex(keywords, match);

    return found;
};

const filteredByAvtale = (oversiktHeader: model.ListHeaderCell[], avtaleFilter?: string) => {
    if (avtaleFilter) {
        return oversiktHeader.filter((celle) => celle.produktkode === avtaleFilter);
    }

    return oversiktHeader;
};

const trackFilterChange = (label: string, value: string) => {
    track({ hendelse: Trackingkey.Filter, label, valg: value, side: "ansattliste" });
};

const sortByHeaderFilter = (insured: model.Person[], headerFilter: HeaderFilter) => {
    if (headerFilter.header === "navn") {
        return insured.sort((a, b) => {
            return headerFilter.direction === "desc"
                ? a.fornavn.localeCompare(b.fornavn, "no")
                : b.fornavn.localeCompare(a.fornavn, "no");
        });
    }

    return insured.sort((a, b) => {
        if (headerFilter.direction === "desc") {
            return b.avtaler.some((avtale) => avtale.produktkode === headerFilter.header) ? 1 : -1;
        } else {
            return a.avtaler.some((avtale) => avtale.produktkode === headerFilter.header) ? 1 : -1;
        }
    });
};

export interface InsuredTableProps {
    headers: model.ListHeaderCell[];
    rows: model.Person[];
    useDetails: (
        indeks: string,
        enabled: boolean
    ) => UseQueryResult<{ entity: model.Detaljer; feilmelding?: KundeFeilmelding }>;
    
    exportLink?: ExportEndpoint;
    meldUtButtonProps: {
        to: (insured: model.Person) => string;
        tracking: () => void;
    };
    meldInnButtonProps: {
        to: (insured: model.Person) => string;
        tracking: () => void;
    };
}

export function InsuredTable({ rows, ...props }: InsuredTableProps) {
    const [match, setMatch] = useState("");
    const [avtaleFilter, setAvtaleFilter] = useState("");
    const headers = filteredByAvtale(props.headers, avtaleFilter);
    const [headerFilter, setHeaderFilter] = useState<HeaderFilter>({ header: "navn", direction: "desc" });
    const [tableColumnWidths, setTableColumnWidths] = useState<Array<number | undefined>>([]);
    const [showAgreementNumber, setShowAgreementNumber] = useState(false);

    const [openRows, setOpenRows] = useState<string[]>([]);

    const handleToggle = (indeks: string) => (isOpen: boolean) => {
        if (isOpen) {
            setOpenRows((currentRows) => [...currentRows, indeks]);
        } else {
            setOpenRows((currentRows) => currentRows.filter((rId) => rId !== indeks));
        }
    };

    const tableColumnRefs = useRef<Array<HTMLTableCellElement | null>>([]);

    useEffect(() => {
        const measureTableColumnWidths = () =>
            setTableColumnWidths(
                tableColumnRefs.current.reduce<Array<number | undefined>>((columnWidths, currentRef) => {
                    if (currentRef) {
                        const { paddingLeft, paddingRight } = getComputedStyle(currentRef);
                        columnWidths.push(currentRef.clientWidth - parseFloat(paddingLeft) - parseFloat(paddingRight));
                    } else {
                        columnWidths.push(undefined);
                    }

                    return columnWidths;
                }, [])
            );

        measureTableColumnWidths();

        window.addEventListener("resize", measureTableColumnWidths);

        return () => {
            window.removeEventListener("resize", measureTableColumnWidths);
        };
        // mål på nytt når avtalefilteret endrer seg, for da endrer kolonnebredden seg
    }, [tableColumnRefs, avtaleFilter]);

    useEffect(() => {
        tableColumnRefs.current = tableColumnRefs.current.slice(0, headers.length);
    }, [headers]);

    const handleSetHeaderFilter = (header: string) => {
        setHeaderFilter((prev) => {
            if (prev.header === header) {
                if (prev.direction === "asc") {
                    return { header, direction: "desc" };
                } else {
                    return { header, direction: "asc" };
                }
            } else {
                return { header, direction: "desc" };
            }
        });
    };

    const insuredList = (() => {
        const insuredList =
            rows
                .filter(searchFilter(match))
                .reduce<[model.Person[], model.Person[]]>(
                    (acc, cur) => {
                        if (cur.avtaler.some((avtale) => avtale.produktkode === avtaleFilter)) {
                            return [[...acc[0], cur], acc[1]];
                        } else {
                            return [acc[0], [...acc[1], cur]];
                        }
                    },
                    [[], []]
                )
                .flatMap((insuredGroup) => sortByHeaderFilter(insuredGroup, headerFilter)) || [];

        if (avtaleFilter && headerFilter.header !== "navn" && headerFilter.direction === "asc") {
            return insuredList.reverse();
        }

        return insuredList;
    })();

    const headerIcon = (header: string) =>
        headerFilter.header === header ? (headerFilter.direction === "asc" ? "↑" : "↓") : null;

    const agreementNumbersByProductCode = rows.reduce<Record<AvtaleProduktID, Array<string>>>(
        (acc, row) => {
            row.avtaler.forEach((avtale) => {
                const produktKode = avtale.produktkode as AvtaleProduktID;
                if (!acc[produktKode]) {
                    acc[produktKode] = [];
                }

                if (!acc[produktKode].includes(avtale.avtaleId)) {
                    acc[produktKode].push(avtale.avtaleId);
                }
            });

            return acc;
        },
        {} as Record<AvtaleProduktID, Array<string>>
    );

    if (!rows.length) {
        return null;
    }

    return (
        <div className="insured-table">
            <div className="insured-table__top-content">
                <div className="insured-table__filter">
                    <SearchField
                        {...{ match, setMatch }}
                        placeholder="Navn eller fødselsnummer"
                        label="Søk i listen"
                        dataTestautoid="insured-table-search-field"
                        className="insured-table__filter-søk"
                        matchedCount={insuredList.length}
                        onBlur={(e) => trackFilterChange("Navn eller fødselsnummer", e.target.value.length.toString())}
                    />

                    <Select
                        name="avtale"
                        label="Velg avtale"
                        defaultPrompt="Alle"
                        items={[
                            { label: "Alle", value: "" },
                            ...props.headers.map((o) => ({
                                label: o.avtaleNavn,
                                value: o.produktkode
                            }))
                        ]}
                        value={avtaleFilter}
                        onChange={(e) => {
                            const { value } = e.target;
                            setAvtaleFilter(value);
                            trackFilterChange("avtale", value);
                        }}
                        width={"270px"}
                    />

                    <Checkbox
                        name="vis-avtalenummer"
                        value="ja"
                        checked={showAgreementNumber}
                        onChange={(e) => setShowAgreementNumber(e.target.checked)}
                        className="jkl-spacing-l--top"
                    >
                        Vis avtalenummer
                    </Checkbox>
                </div>

                {props.exportLink && <ExcelExportLink export={props.exportLink} />}
            </div>

            <Table collapseToList fullWidth className="insured-table__list">
                <TableHead sticky>
                    <TableRow className="insured-table__heading">
                        <TableHeader
                            density="compact"
                            bold={false}
                            ref={(el) => (tableColumnRefs.current[0] = el)}
                            onClick={() => handleSetHeaderFilter("navn")}
                        >
                            Navn {headerIcon("navn")}
                        </TableHeader>
                        {headers.map((header, i) => (
                            <TableHeader
                                key={header.avtaleNavn}
                                density="compact"
                                bold={false}
                                ref={(el) => (tableColumnRefs.current[i + 1] = el)}
                                onClick={() => handleSetHeaderFilter(header.produktkode)}
                            >
                                {header.avtaleNavn} {headerIcon(header.produktkode)}
                                {showAgreementNumber && (
                                    <>
                                        <br />
                                        <Typography variant="small" component="span">
                                            {agreementNumbersByProductCode[header.produktkode as AvtaleProduktID]
                                                .map((avtalenummer) =>
                                                    formatAvtalenummer(
                                                        avtalenummer,
                                                        header.produktkode as AvtaleProduktID
                                                    )
                                                )
                                                .join(", ")}
                                        </Typography>
                                    </>
                                )}
                            </TableHeader>
                        ))}
                        <TableHeader srOnly>Mer informasjon</TableHeader>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {insuredList.map((insured) => (
                        <InsuredTableRow
                            key={insured.indeks}
                            headers={headers}
                            insured={insured}
                            agreementNumbersByProductCode={agreementNumbersByProductCode}
                            showAgreementNumber={showAgreementNumber}
                            handleToggle={handleToggle(insured.indeks)}
                            isOpen={openRows.includes(insured.indeks)}
                            tableColumnWidths={tableColumnWidths}
                            useDetails={props.useDetails}
                            meldUtButtonProps={props.meldUtButtonProps}
                            meldInnButtonProps={props.meldInnButtonProps}
                        />
                    ))}
                </TableBody>
            </Table>
        </div>
    );
}
